import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/index'
import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Popconfirm, Drawer,
  Row,
  Col,
  Switch,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import S from 'string'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import QuestionDetails from '../../../components/questions/questionDetails'

import { getUrlPushWrapper } from '../../../routes'

class AllQuestions extends Component {
  deleteQuestion = async data => {
    this.setState({ loading: true })
    let x = await Request.deleteQuestion(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })

    }
    else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })
    }


  }

  state = {
    singleData: {},
    visible: false,
    taskArr: [],
    sectionArr: []
  }
  apiRequest = (params, columns) => {
    return new Promise(async (resolve) => {
      //  let regExFilters = _.map(columns, x => x.key)
      params.sortField = '_id'
      params.sortOrder = 'desc'
      let data = await Request.getAllQuestions({
        ...params, regExFilters: [
          'updatedByUser.name', 'createdByUser.name', 'taskId', 'body', 'tags'
        ]
      })
      this.setState({ totalCount: data.total })
      resolve(data)
    })
  }
  state = {
    singleData: {},
    visible: false,
    visibleAudio: false
  }
  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  showAudio = (data) => {
    this.setState({
      visibleAudio: true,
      selectedAudio: data.audio && data.audio.length ? data.audio[0] : null
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()

  }

  async componentDidMount() {
    let taskArr = await
      Request.getTaskList()
    let taskArray = _.map(taskArr, x => x.task)

    let sectionArray = _.uniq(_.map(taskArr, x => x.section))
    this.setState({ taskArr: taskArray, sectionArr: sectionArray })

  }

  render() {
    const { dispatch } = this.props
    let { taskArr, sectionArr } = this.state
    const columns = [
      {
        title: 'Question',
        key: 'body',
        dataIndex: 'body',
        searchTextName: 'body',
        render: (u) => {
          return <div className={styles.question}>{u ? S(u).stripTags().s : null}</div>
        }
      },
      {
        title: 'Type',
        dataIndex: 'taskName',
        key: 'taskName',
        filters: taskArr && taskArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.taskName ? record.taskName : ''}</div>

          )
        }
      },
      {
        title: 'Task No',
        key: 'taskId',
        dataIndex: 'taskId',
        searchTextName: 'taskId'
      },
      {
        title: 'Section',
        dataIndex: 'section',
        key: 'section',
        filters: sectionArr && sectionArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>
              {record ? record.section : ''}<br/>
              {record && record.section == 'Listening' ?
                <React.Fragment>
                  <Button onClick={() => this.showAudio(record)}>Play</Button>
                </React.Fragment> : null
              }
            </div>

          )
        }

      },

      {
        title: 'Tags',
        width: 100,
        dataIndex: 'tags',
        key: 'tags',
        searchTextName: 'taskId',
        render: cat => {
          return cat && cat.join(',')
        }
      },
      {
        title: 'Created By',
        width: 150,
        key: 'createdByUser.name',
        dataIndex: 'createdByUser',
        searchTextName: 'createdByUser.name',

        render: (text, record) => {
          return (
            <div>
              <div>{text ? text.name : ''}</div>
              <small>{text ? moment(text.time).format('lll') : ''}</small>
            </div>
          )
        }
      },
      {
        title: 'Updated By',
        width: 150,
        key: 'updatedByUser.name',
        dataIndex: 'updatedByUser',
        searchTextName: 'updatedByUser.name',

        render: (text, record) => {
          return (
            <div>
              <div>{text ? text.name : ''}</div>
              <small>{text ? moment(text.time).format('lll') : ''}</small>
            </div>
          )
        }
      },

      {
        key: 'actions',
        title: 'Actions', fixed: 'right',
        width: 150,
        render: (text, record) => {

          return <React.Fragment>
            <Tooltip title="View Question Details">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                onClick={() => {
                  this.setState({
                    singleData: record
                  }, () => {
                    this.showDrawer()
                  })
                }}
                icon="eye"
              />
            </Tooltip>
            <Tooltip title="Edit Question">
              <a shape="circle"
                 style={{ marginRight: 6 }} href={`/question/edit-question?id=${record._id}`}>
                <Button
                  shape="circle" icon="edit"/>
              </a>

            </Tooltip><Tooltip title="Delete Question">
            <Popconfirm
              title="Are you sure delete this Question?"
              onConfirm={() => {
                this.deleteQuestion({ _id: record._id })
              }}
              onCancel={() => {
                console.log()
              }}
              okText="Yes"
              cancelText="No">
              <Button
                shape="circle"
                icon="delete"
              />
            </Popconfirm>

          </Tooltip>

          </React.Fragment>

        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={`All Questions (${this.state && this.state.totalCount})`}>


        <Card bordered={true}>

          <TableComp ref={this.table} columns={columns}
                     scroll={{ x: 1400 }}
                     apiRequest={(params) => this.apiRequest(params, columns)}/>

        </Card>


        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          visible={this.state.visible}
          width={600}>
          <div>
            {console.log(this.state.singleData)}<QuestionDetails ques={this.state.singleData}/>
            {/*{JSON.stringify(this.state.singleData)}*/}
          </div>
        </Drawer>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visibleAudio: false,
              selectedAudio: null
            })
          }}
          visible={this.state.visibleAudio}
          width={600}>
          <div>
            <Card bordered={true} title={'Audio'}>
              {this.state.selectedAudio && this.state.selectedAudio.url ?
                <audio src={this.state.selectedAudio.url} controls autoPlay className={'audioWidth'}/>
                /*<audio controls autoplay>
                  <source src={this.state.selectedAudio.url} type="audio/ogg"/>
                </audio>*/ :
                <div>
                  Not Audio file Available
                </div>
              }


            </Card>


          </div>
        </Drawer>

      </PageHeaderWrapper>)

  }
}

// class QuestionDetails extends Component {
//
//
//     render() {
//         const {ques} = this.props
//         console.log(ques, "----")
//         if (ques.taskId == 2) {
//           return  <div><Row><Col>
//                 <label>Recording Duration<p>{ques.recordingDuration}</p></label>
//               <label>Preparation Duration<p>{ques.preparationDuration}</p></label>
//
//           </Col></Row></div>
//         }
//        // return <div>asdfasdf sadfsadf sdf</div>
//
//     }
// }

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllQuestions)
