import React, { Component } from 'react'
// import PageHeaderWrapper from '../../../components/PageHeaderWrapper/index'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/index'
import ReactAudioPlayer from 'react-audio-player'
import { apiUrl } from '../../settings'
import { goBack } from 'connected-react-router'
import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Popconfirm, Drawer,
  Tag,
  InputNumber,
  Row,
  Col,
  Switch,
  Input, Button, Icon, Checkbox
} from 'antd'
import Request from '../../request'
import S from 'string'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './style.less'
import { connect } from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'

import { getUrlPushWrapper } from '../../routes'
import { hideLoader, showLoader } from '../../modules/actions'
import { Form } from 'antd/lib/form'
const {TextArea} = Input

class CollapseViewBox extends Component {

  state = {
      cp: true,
  }

  render() {
    const { cp } = this.state
    return <React.Fragment>
      <div className={cp ? styles.questioncollapse : styles.questioncollapsenot}>{this.props.children}</div>
      <a style={{ color: 'red' }} onClick={() => {
        this.setState({
          cp: !cp
        })
      }}>
        {cp ? <p>Read More + </p> : <p>Read Less - </p>}
      </a>

    </React.Fragment>
  }
}


class checkTest extends Component {


  renderAnswer = (val) => {

    if (val.reorderOptions && val.reorderOptions.length) {
      return <div>
        <ul>
          {val.reorderOptions && _.map(val.reorderOptions, (item) => {
            return <li>{item.value}</li>
          })}
        </ul>
      </div>
    }
    if (val.options && val.options.length) {

      return <div>
        {val.options && _.map(val.options, (item) => {
          return item.value
        })}

      </div>


    }
    if (val.text) {
      return <div>{val.text}</div>

      // if (val.text.length <= 20) {
      //   return <div>{val.text}</div>
      // }
      // return <CollapseViewBox>{val.text}</CollapseViewBox>

    }


    if (val.fibOptions && val.fibOptions.length) {
      return <div>
        <ul>
          {val.fibOptions && _.map(val.fibOptions, (item) => {
            return <li>{item.value}</li>
          })}
        </ul>
      </div>
    }
    if (val.recording && val.recording.length) {
      return <div>
        <ReactAudioPlayer
          src={val.recording[0].url}
          autoPlay={false}
          controls
        />

      </div>
    }


  }
  renderQuestion = (record) => {

    if (record.taskId && record.taskId == 1) {

      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          return <div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>
        }
        return <CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>
      }

    }
    if (record.taskId && record.taskId == 2) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>
      }

    }
    if (record.taskId && record.taskId == 3) {
      if (record.questionImage && record.questionImage.length) {
        return <div>{record.questionImage && _.map(record.questionImage, (item) => {
          return <img src={item.url} style={{ width: 100 }}/>
        })}</div>
      }

    }
    if (record.taskId && record.taskId == 4) {
      let out = []
      if (record.questionImage && record.questionImage.length) {
        out.push(<div style={{ paddingBottom: 10 }}>{record.questionImage && _.map(record.questionImage, (item) => {
          return <img src={item.url} style={{ width: 100 }}/>
        })}</div>)
      }
      if (record.audio && record.audio.length) {
        out.push(<div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }

      return out

    }
    if (record.taskId && record.taskId == 5) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />
        </div>
      }
    }
    if (record.taskId && record.taskId == 6) {
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          return <div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>
        }
        return <CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>
      }

    }
    if (record.taskId && record.taskId == 7) {
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          return <div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>
        }
        return <CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>
      }
    }
    if (record.taskId && record.taskId == 8) {
      let out = []
      if (record.textArray && record.textArray.length) {
        out.push(<div>
          {record.textArray && _.map(record.textArray, (item, index) => {
            return <span>{item}
              {
                record.fibOptions[index] && record.fibOptions[index].options.map((fibVal, oo) => {
                  return <Tag key={oo}>{fibVal.value} {fibVal.correct &&
                  <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</Tag>
                })
              }</span>
          })}
        </div>)

      }

      return out
    }
    if (record.taskId && record.taskId == 9) {
      let out = []

      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }

      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div style={{ paddingTop: 10 }}>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }
      return out
    }
    if (record.taskId && record.taskId == 10) {
      let out = []
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div style={{ paddingTop: 10 }}>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }
      return out

    }
    if (record.taskId && record.taskId == 11) {
      if (record.reorderParagraph && record.reorderParagraph.length) {
        return <div>
          <ul>{record.reorderParagraph && _.map(record.reorderParagraph, (item) => {
            return <li>{item.value} <span className={styles.label}>{item.orderNo}</span></li>
          })}</ul>
        </div>
      }

    }

    if (record.taskId && record.taskId == 12) {
      let out = []

      if (record.textArray && record.textArray.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          {record.textArray && _.map(record.textArray, (item, index) => {
            return <span>{item} {index < record.textArray.length - 1 ? <Button style={{
              width: 60, height: 25, marginLeft: 1,
              marginRight: 8
            }}></Button> : null}</span>
          })}
        </div>)
      }
      if (record.reorderParagraph && record.reorderParagraph.length) {
        out.push(<div>
          {record.reorderParagraph && _.map(record.reorderParagraph, (item) => {
            return <Tag>{item.value} , {item.orderNo}</Tag>
          })}
        </div>)
      }

      return out
    }

    if (record.taskId && record.taskId == 13) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>
      }
    }
    if (record.taskId && record.taskId == 14) {
      let out = []
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10, paddingTop: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }

      return out

    }

    if (record.taskId && record.taskId == 15) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.textArray && record.textArray.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          {record.textArray && _.map(record.textArray, (item, index) => {
            return <span>{item} {index < record.textArray.length - 1 ? <Button style={{
              width: 60, height: 25, marginLeft: 1,
              marginRight: 8
            }}></Button> : null}</span>
          })}
        </div>)
      }
      if (record.reorderParagraph && record.reorderParagraph.length) {
        out.push(<div>
          {record.reorderParagraph && _.map(record.reorderParagraph, (item) => {
            return <Tag>{item.value} , {item.orderNo}</Tag>
          })}
        </div>)
      }

      return out

    }

    if (record.taskId && record.taskId == 16) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }

      return out
    }
    if (record.taskId && record.taskId == 17) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }


      return out
    }
    if (record.taskId && record.taskId == 18) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }


      return out
    }
    if (record.taskId && record.taskId == 19) {

      let out = []
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10, paddingTop: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }


      return out

    }
    if (record.taskId && record.taskId == 20) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>
      }
    }

  }

  constructor(props) {
    super(props)
    this.state = {
      obtainedMarksResult: [],
      checked: false,
        tempCorrect: [],
        feedback: null

    }
    this.table = React.createRef()

  }

  async componentDidMount() {

    let searchParams = new URLSearchParams(this.props.search)
    let bookedTestId = searchParams.get('id')

    if (bookedTestId) {

      let studentTest = await Request.getStudentTestById({ id: bookedTestId })
      if (studentTest) {
        let { data, error, message } = studentTest
        if (!error) {

          this.setState({
            studentTestData: data.questions,
            testName: data.testName,
            testDate: data.testDate,
            testNo: data.testNo,
              id: bookedTestId,
              feedback: data.feedback
          })
        }


      }


    }

  }

  async updateMarks() {
    if (this.state.studentTestData && this.state.id) {

      let { studentTestData } = this.state
      let studentTestObj = []
      _.each(studentTestData, (item, key) => {
        /*if (item.obtainedMarks) {

         }*/
          studentTestObj.push({
              questionId: item.questionId,
              obtainedMarks: item.obtainedMarks
          })
      })
      let obj = {
        studentTestId: this.state.id,
        // marksData: this.state.studentTestData,
        marksData: studentTestObj,
          testStatus: 'Checked',
          feedback: this.state.feedback
      }
      this.props.dispatch(showLoader())
      let x = await Request.addStudentTestMarks(obj)
      this.props.dispatch(hideLoader())
      if (!x.error) {
        notification.success({ message: x.message })
        window.open(`${apiUrl}/student/result/${this.state.id}`, '_blank')

        this.props.dispatch(goBack())
      }
      else {
        notification.error({ message: 'Error saving data' })
      }

      /*  let marksData = _.find(studentTestData, (val) => {
          return val.obtainedMarks == null
        })

        if (!marksData) {
          let obj = {
            studentTestId: this.state.id,
            marksData: this.state.studentTestData,
            testStatus: 'Checked'
          }
          dispatch(showLoader())
          let x = await Request.addStudentTestMarks(obj)
          dispatch(hideLoader())
          if (!x.error) {
            notification.success({ message: x.message })
            window.open(`${apiUrl}/student/result/${this.state.id}`, '_blank')

            this.props.dispatch(goBack())
          }
          else {
            notification.error({ message: 'Error saving data' })
          }
        }
        else {
          notification.error({ message: 'Marks cannot be empty' })

        }*/
    }
  }

  render() {
    const { dispatch } = this.props
    const { obtainedMarksResult } = this.state
    const columns = [

      {
        title: 'Question',
        // key: 'body',
        // dataIndex: 'body',
        render: (u, record) => {
          return this.renderQuestion(record)

        }
      },
      {
        title: 'Type',
        dataIndex: 'taskName',
        key: 'taskName',
        render: (text, record) => {
          return (
            <div>
              {record.taskName ? record.taskName : ''}<br/>
              <b style={{ paddingTop: 10 }}>Total:{record.marks ? record.marks : ''}</b>
            </div>

          )
        }
      },
      {
        title: 'Task No',
        key: 'taskId',
        dataIndex: 'taskId'
      },
      {
        title: 'Section',
        key: 'section',
        dataIndex: 'section',

        render: (text, record) => {
          return (
            <div>
              {record ? record.section : ''}

            </div>

          )
        }

      },
      {
        title: 'Answers',
        key: 'answers',
        dataIndex: 'answers',
        render: (u, record) => {
          return (
            <div>
              {record.taskId == 19 ?
                <ul>
                  {u.options.length && u.options.map((item, index1) => {
                    return <li key={index1}>{item.value}</li>
                  })}
                </ul> : this.renderAnswer(u)
              }
            </div>
          )
        }
      },
      {
        title: 'Obtained/Total Marks',
        key: 'marks',
        dataIndex: 'marks',
        width: 150,

        render: (text, record) => {
          return (
            <div>

              <InputNumber
                value={record.obtainedMarks}
                style={{ width: 75, height: 30, margin: 5 }}
                onChange={(py) => {
                    py = py ? parseInt(py) : ''
                    let main = this.state.studentTestData
                    let rec = _.find(main, x => x._id == record._id)
                    rec.obtainedMarks = py
                    rec.negChecked = false

                    this.setState({studentTestData: main})
                 

                }}/><span style={{ fontSize: 20 }}>{`/ ${record && record.marks}`}</span>

              <Checkbox
                checked={record.negChecked}
                style={{ paddingLeft: 10 }}
                onChange={y => {

                  let main = this.state.studentTestData
                  let rec = _.find(main, x => x._id == record._id)
                  if (y.target.checked) {
                    rec.obtainedMarks = -1
                    rec.negChecked = true
                  } else {
                    rec.obtainedMarks = null
                    rec.negChecked = false
                  }

                  this.setState({ studentTestData: main })
                }}>
              </Checkbox><span style={{ paddingLeft: 5 }}>Negative</span>

            </div>

          )
        }

      }


    ]

    return (
      <PageHeaderWrapper
        title={`Test Status`}>
        <Card bordered={true} style={{ marginBottom: 10 }}>

          <div>
            <Row>
              <Col span={8}>
                <b>Test Name: {this.state && this.state.testName}</b>

              </Col>
              <Col span={8}>
                <b>Test Date: {moment(this.state.testDate).format('YYYY-MM-DD')} </b>

              </Col>

              <Col span={8}>
                <b>Test Number: {this.state && this.state.testNo}</b>

              </Col>


            </Row>
          </div>

        </Card>

        <Card bordered={true} style={{ marginBottom: 10 }}>
          <div>
            <Row>
              <Col>
                <Table
                  bordered
                  columns={columns}
                  rowKey={record => record._id}
                  size={this.state.size}
                  dataSource={this.state && this.state.studentTestData}
                  pagination={false}
                  onChange={() => {
                  }}
                  loading={this.props.loading}
                />
              </Col>
            </Row>
            <Row style={{padding: 10}}>
              <Col span={24}> Feedback

              </Col>
              <Col span={24}>
                <TextArea value={this.state.feedback} onChange={(e) => {
                    this.setState({feedback: e.target.value})
                }}/>
              </Col>

            </Row>
            <Row>
              <Col>
                <Button type="primary" htmlType="submit" loading={this.props.loading} style={{ marginTop: 20 }}
                        onClick={() => {
                          this.updateMarks()
                        }}>
                  SAVE
                </Button>
              </Col>
            </Row>
          </div>


        </Card>
      </PageHeaderWrapper>)

  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  loading: global.buttonLoading,
  search: router.location.search


})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(checkTest)
