import axios from 'axios'

import { apiUrl } from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
  baseURL: apiUrl
})


let getToken = () => {
  return ({ 'headers': { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
}


class Request {

  error = (err) => {
    try {
      if (err.response.status === 401) {
        localStorage.clear()
      }
    } catch (e) {
    }
  }

  constructor() {

  }

  login(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/login', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getAllUser(data) {
    return new Promise((next) => {
      authAxios
        .get('/user/list', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getAllQuestions(data) {
    return new Promise((next) => {
      authAxios
        .get('/questions/list', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  addUser(data) {
    return new Promise((next) => {
      authAxios
        .post('/signup', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getUserById(data) {
    return new Promise(next => {
      authAxios
        .get('/user/' + data.id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateUser(data) {
    return new Promise(next => {
      authAxios
        .put('/updateUser', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteUser(data) {
    return new Promise((next, error) => {
      authAxios
        .delete('/user/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          error(err)
          this.error(err)
        })
    })
  }

  addTest(data) {
    return new Promise((next) => {
      authAxios
        .post('/test/add', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getAllTests(data) {
    return new Promise((next) => {
      authAxios
        .get('/test/list', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  deleteTest(data) {
    return new Promise((next, error) => {
      authAxios
        .delete('/test/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          error(err)
          this.error(err)
        })
    })
  }

  getTestById(data) {
    return new Promise(next => {
      authAxios
        .get('/test/' + data.id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateTest(data) {
    return new Promise(next => {
      console.log(data)
      authAxios
        .put('/test/' + data._id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllStudents(data) {
    return new Promise((next) => {
      authAxios
        .get('/allStudents/list', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  addAssignedStudentTest(data) {
    return new Promise((next) => {
      authAxios
        .post('/assignedStudents/test', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getAllBookedTests(data) {
    return new Promise((next) => {
      authAxios
        .get('/viewAllBookedTests', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  questionDataByIds(data) {
    return new Promise((next) => {
      authAxios
        .post('/questionsByIds', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }


  //region Questions
  getTaskList(data) {
    return new Promise((next) => {
      authAxios
        .get('/allTasks')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }


  addQuestion(data) {
    return new Promise((next) => {
      authAxios
        .post('/question/add', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  deleteQuestion(data) {
    return new Promise((next, error) => {
      authAxios
        .delete('/question/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          error(err)
          this.error(err)
        })
    })
  }

  getQuestionById(data) {
    return new Promise(next => {
      authAxios
        .get('/question/' + data.id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateQuestion(data) {
    return new Promise(next => {
      console.log(data)
      authAxios
        .put('/question/' + data._id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  //endregion

  getSingleTest(data) {
    return new Promise(next => {
      authAxios
        .get('/singleTest/' + data.id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getPaymentDetails(data) {
    return new Promise(next => {
      authAxios
        .get('/student/payments/Details/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getBookedTest(data) {
    return new Promise(next => {
      authAxios
        .get('/student/bookedTest/Details/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  examHistory(data) {
    return new Promise(next => {
      authAxios
        .get('/student/testHistory/Details/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  updateTestQuestionIndexing(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/updateTestQuestionIndexing', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  forgotPassword(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/forgotPassword', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getBookedTestByStudents(data) {
    return new Promise((next) => {
      authAxios
        .post('/getBookedTestByStudents', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  changeUserPassword(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/changePassword', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          error(err)
          this.error(err)
        })
    })
  }

  getAllPayments(data) {
    return new Promise((next) => {
      authAxios
        .get('/payments/list', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  notBookedStudentsList(data) {
    return new Promise((next) => {
      authAxios
        .get('/notBookedTestByStudentsList/' + data.testId, getToken())
        .then(({ data, error, message }) => {
          next(data.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  studentList(data) {
    return new Promise((next) => {
      authAxios
        .post('/getTypeAheadStudentList', data, getToken())
        .then(({ data, error, message }) => {
          next(data.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getStudentTestById(data) {
    return new Promise(next => {
      authAxios
        .get('/testDetails/' + data.id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addStudentTestMarks(data) {
    return new Promise(next => {
      authAxios
        .post('/save/testMarks', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  lockUnlockStudent(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/lockUnlockStudent', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  deleteBookedTest(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/deleteBookedTest', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  loginActivity(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/loginActivity', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  chartApi(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/chart/api', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  addStudentTestMarksWithEnableSkills(data) {
    return new Promise(next => {
      authAxios
        .post('/save/testMarksEnableSkill', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
}

export default new Request()
