import React, { Component, PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Row, Col,
  Input,
  DatePicker,
  Select,
  Button,
  Tag,
  Card,
  Icon, Tooltip, Table, List
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import styles from './styles.less'

import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import ReactDragListView from 'react-drag-listview'
import { createMatchSelector } from 'connected-react-router'
import ReactAudioPlayer from 'react-audio-player'

const FormItem = Form.Item
const { Option } = Select

class CollapseViewBox extends Component {

  state = {
    cp: true
  }

  render() {
    const { cp } = this.state
    return <React.Fragment>
      <div className={cp ? styles.questioncollapse : styles.questioncollapsenot}>{this.props.children}</div>
      <a style={{ color: 'red' }} onClick={() => {
        this.setState({
          cp: !cp
        })
      }}>
        {cp ? <p>Read More + </p> : <p>Read Less - </p>}
      </a>

    </React.Fragment>
  }
}


@Form.create()
class OrderingTest extends PureComponent {

  loadTaskDefinations = async (v) => {
    let taskArr = await Request.getTaskList()
    let taskObject = {}
    _.each(taskArr, (item) => {
      taskObject[item.id] = {
        task: item.task,
        section: item.section
      }
    })
    this.setState({ taskObject })

  }

  async updateIndexing() {
    let { data, id } = this.state
    let questions = []
    let key = 0
    _.each(data, (item) => {
      _.each(item.questions, (item1) => {
        questions.push({ questionId: item1.questionId._id, marks: item1.marks, index: key })
        key++
      })
    })
    let resp = await Request.updateTestQuestionIndexing({ _id: id, questions: questions })
    if (resp.success) {
      notification.success({ message: resp.msg })
      this.getUpdateIndexing()
    }
    else {
      notification.error({ message: resp.msg })
    }

  }

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      testArray: [],
      taskObject: {},
      questionList: []
    }

  }

  componentDidMount() {
    this.getUpdateIndexing()
    this.loadTaskDefinations()
  }

  async getUpdateIndexing() {
    let searchParams = new URLSearchParams(this.props.search)

    let testId = searchParams.get('id')

    if (testId) {
      Request.getSingleTest({ id: testId }).then(
        ({ data, error, message }) => {
          if (!error) {
            let groupData = _.groupBy(data.questions, (item) => {
              return item.questionId.taskId
            })
            let questionList = []
            _.each(groupData, (item, index) => {
              let obj = { key: index, questions: [] }
              let sortData = _.sortBy(item, (aa) => {
                return aa.index
              })
              _.each(sortData, (item1, key) => {
                item1.key = key
                obj.questions.push(item1)
              })
              questionList.push(obj)
            })
            let cloneTest = _.clone(data)
            delete cloneTest['questions']
            this.setState({
              id: data._id,
              data: questionList,
              test: cloneTest
            })

          } else {
            notification.error({
              message: 'Error Getting Data',
              description: message
            })
          }
        }
      )
    }
  }

  render() {

    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props
    const { taskObject, test } = this.state
    const that = this
    const dragPropsFxn = (dataNew, index) => {
      if (dataNew) {
        let dragProps = {
          onDragEnd(fromIndex, toIndex) {
            const data = Object.assign([], dataNew)
            const item = data.splice(fromIndex, 1)[0]
            data.splice(toIndex, 0, item)
            const cloneData = Object.assign([], that.state.data)
            cloneData[index].questions = data
            that.setState({
              data: cloneData
            })
          },
          handleSelector: 'a'
        }
        return dragProps
      }

    }


    const columns = [

      {
        title: '#',
        dataIndex: 'key',
        width: 100,
        render: (key) => {
          return (
            <React.Fragment>{key + 1}</React.Fragment>
          )
        }
      },
      /*{
        title: 'Task Id',
        width: 100,
        dataIndex: 'questionId.taskId',
        key: 'questionId.taskId'

      },*/
      {
        title: 'Question',
        dataIndex: 'questionId.body',
        key: 'questionId.body',
        render: (item, record) => {
          return (
            <div>
              {item ? <div dangerouslySetInnerHTML={{ __html: item }}></div> :
                record.questionId.audio && record.questionId.audio.length ? <ReactAudioPlayer
                    src={record.questionId.audio[0].url}
                    autoPlay={false}
                    controls
                  /> :
                  record.questionId.questionImage && record.questionId.questionImage.length ?
                    <div>{record.questionId.questionImage && _.map(record.questionId.questionImage, (item) => {
                      return <img src={item.url} style={{ width: 100 }}/>
                    })}</div> :
                    record.questionId.taskId == 8 && record.questionId.textArray && record.questionId.textArray.length ?
                      <CollapseViewBox>{record.questionId.textArray && _.map(record.questionId.textArray, (item, index) => {
                        return <span>{item}
                          {
                            record.questionId.fibOptions[index] && record.questionId.fibOptions[index].options.map((fibVal, oo) => {
                              return <Tag key={oo}>{fibVal.value} </Tag>
                            })
                          }</span>
                      })}</CollapseViewBox> :

                      record.questionId.taskId == 11 && record.questionId.reorderParagraph && record.questionId.reorderParagraph.length ?
                        <div>

                          {

                            <ul>{record.questionId.reorderParagraph && _.map(record.questionId.reorderParagraph, (item) => {
                              return <li>{item.value} </li>
                            })}</ul>

                          }

                        </div> :
                        record.questionId.taskId == 12 && record.questionId.textArray && record.questionId.textArray.length ?
                          <CollapseViewBox>{record.questionId.textArray && _.map(record.questionId.textArray, (item, index) => {
                            return <span>{item}
                              {index && <Button style={{
                                width: 60, height: 25, marginLeft: 3,
                                marginRight: 8
                              }}></Button>}</span>
                          })}</CollapseViewBox> : null


              }

            </div>

          )
        }

      },
      {
        title: '',
        key: 'operate',
        width: 100,
        render: (text, record) => {
          return (
            <a className="drag-handle" href="#" style={{ cursor: 'move' }}>Move</a>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper
        title={test ? `Ordering Test Question - ${test.testName}` : null}
        content={''}>
        <card bordered={true}>
          {this.state.data && this.state.data.length ?
            <div>
              <Button onClick={() => this.updateIndexing()} type={'default'}>Update Ordered</Button>
              <br/>
              {this.state.data.map((item, index) => {
                return (
                  <ReactDragListView {...dragPropsFxn(item.questions, index)} key={index}>
                    <div className={styles.tableRow}>
                      <div className={styles.tableRowHeader}>
                        {taskObject[item.key] && `Task - ${item.key}. ${taskObject[item.key].task} (${taskObject[item.key].section})`}
                      </div>
                      <Table
                        columns={columns}
                        pagination={false}
                        dataSource={item.questions}
                      />
                    </div>
                  </ReactDragListView>
                )
              })}

            </div> : null}

        </card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderingTest)
