import React, { PureComponent, Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Row, Col,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon, Tooltip, Table, Tag
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import styles from './styles.less'
import { getUrlPushWrapper } from '../../../routes'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils'
// import GetAllFormFields from '../../../components/_utils/formUtils'
import {apiUrl} from '../../../settings'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import ReactAudioPlayer from 'react-audio-player'

import { createMatchSelector } from 'connected-react-router'
import S from 'string'

const FormItem = Form.Item
const { Option } = Select

class CollapseViewBox extends Component {

  state = {
    cp: true
  }

  render() {
    const { cp } = this.state
    return <React.Fragment>
      <div className={cp ? styles.questioncollapse : styles.questioncollapsenot}>{this.props.children}</div>
      <a style={{ color: 'red' }} onClick={() => {
        this.setState({
          cp: !cp
        })
      }}>
        {cp ? <p>Read More + </p> : <p>Read Less - </p>}
      </a>

    </React.Fragment>
  }
}

@Form.create()
class AddTest extends PureComponent {


  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()

    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)
        if (values.listeningTime) {
            values.listeningTime = values.listeningTime * 60000
        }
        if (values.readingTime) {
            values.readingTime = values.readingTime * 60000
        }

        dispatch(showLoader())

        let x = null

        if (this.state.testArray && this.state.id) {

          let { testArray } = this.state

          let marksData = _.find(testArray, (val) => {
            return val.marks == null
          })

          if (!marksData) {
            values._id = this.state.id
            values.questions = this.state.testArray
            x = await Request.updateTest(values)
          }
          else {
            notification.error({ message: 'Marks cannot be empty' })
          }


        } else {

          if (this.state.testArray) {

            let { testArray } = this.state

            let marksData = _.find(testArray, (val) => {
              return val.marks == null
            })

            if (!marksData) {
              values.questions = this.state.testArray
              x = await Request.addTest(values)

            }
            else {

              notification.error({ message: 'Marks cannot be empty' })

            }
          }

        }

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: this.state.id ? 'Test updated successfully' : 'Test added successfully'
          })


          // window.location.href = `/test/ordering-test/${x.data._id}`

          dispatch(
            getUrlPushWrapper('tests.orderingTest', {
              id: x.data._id
            })
          )

          if (!this.state.id) {
            this.props.form.setFieldsValue({})
            form.resetFields()
            this.setState({ testArray: [] })
          }
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }


  apiRequest = (params, columns) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllQuestions({
        ...params, regExFilters: [
          'taskId', 'body', 'tags'
        ]
      })
      this.setState({ totalCount: data.total })
      resolve(data)
    })
  }


  defaultMarksUpdateTime = (item) => {
    if (this.state.testArray) {

      let foundData = _.find(this.state.testArray, (v) => {
        return v.questionId == item.questionId
      })
      return foundData.marks
    }


  }
  filterData = (pagination, filters, sorter) => {
    let cloneData = _.clone(this.state.tempTestArray)
    if (filters) {
      if (filters.taskName && filters.taskName.length) {
        cloneData = _.filter(cloneData, (item) => {
          return item.taskName.match(new RegExp(filters.taskName[0], 'ig'))
        })
      }
      if (filters.section && filters.section.length) {
        cloneData = _.filter(cloneData, (item) => {
          return item.section.match(new RegExp(filters.section[0], 'ig'))
        })
      }
    }
    setTimeout(() => {
      this.setState({
        testArray: cloneData
      })
    }, 1000)

  }


  renderQuestion = (record) => {

    if (record.body) {
      let u = record.body
      if (u.length <= 200) {
        return <div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>
      }
      return <CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>
    }
    if (record.audio && record.audio.length) {
      return <div>
        <ReactAudioPlayer
          src={record.audio[0].url}
          autoPlay={false}
          controls
        />

      </div>
    }
    if (record.questionImage && record.questionImage.length) {
      return <div>{record.questionImage && _.map(record.questionImage, (item) => {
        return <img src={item.url} style={{ width: 100, height: 100 }}/>
      })}</div>
    }
    if (record.taskId == 8) {

      if (record.textArray && record.textArray.length) {
        return <CollapseViewBox>
          {record.textArray && _.map(record.textArray, (item, index) => {
            return <span>{item}
              {
                record.fibOptions[index] && record.fibOptions[index].options.map((fibVal, oo) => {
                  return <Tag key={oo}>{fibVal.value} </Tag>
                })
              }</span>
          })}
        </CollapseViewBox>

      }

    }


    if (record.reorderParagraph && record.reorderParagraph.length) {
      return <div>
        <ul>{record.reorderParagraph && _.map(record.reorderParagraph, (item) => {
          return <li>{item.value}</li>
        })}</ul>
      </div>
    }


  }


  constructor(props) {
    super(props)
    this.state = {
      testArray: [],
      tempTestArray: [],
      marksValue: '',
      sectionArr: [],
      taskArr: []
    }


  }

  async componentDidMount() {


    let searchParams = new URLSearchParams(this.props.search)

    let testId = searchParams.get('id')

    if (testId) {
      let testData = await Request.getTestById({ id: testId })
      if (testData) {
        let { data, error, message } = testData
        if (!error) {

          this.setState({
            id: data._id,
            key: Math.random() & 100
          })

          if (data.testDate) {
            data.testDate = moment(data.testDate)
          }
          if (data.publishedDate) {
            data.publishedDate = moment(data.publishedDate)
          }
          if (data.questions) {
            let ids = _.map(data.questions, (v) => {
                return v.questionId
              }
            )

            let questionData = await
              Request.questionDataByIds(ids)

            let questionItem = []

            _.each(questionData.data, (ques) => {
              let questionJson = {}

              questionJson.questionId = ques._id
              questionJson.taskId = ques.taskId
              questionJson.body = ques.body
              questionJson.audio = ques.audio
              questionJson.questionImage = ques.questionImage
              questionJson.mcqOptions = ques.mcqOptions
              questionJson.fibOptions = ques.fibOptions
              questionJson.reorderParagraph = ques.reorderParagraph
              questionJson.textArray = ques.textArray
              questionJson.section = ques.section
              questionJson.taskName = ques.taskName
              let found = _.find(data.questions, (v) => {
                return v.questionId == ques._id

              })
              if (found) {
                questionJson.marks = parseInt(found.marks)
              }
              questionItem.push(questionJson)

            })


            this.setState({ testArray: questionItem, tempTestArray: questionItem })
          }

          this.props.form.setFieldsValue({
            testDuration: data.testDuration,
            testName: data.testName,
            testDate: data.testDate,
            price: data.price,
            speakingWritingTime: data.speakingWritingTime,
              readingTime: data.readingTime ? data.readingTime / (60000) : null,
            instructions: data.instructions,
              listeningTime: data.listeningTime ? data.listeningTime / (60000) : null,
            publishedDate: data.publishedDate
          })

        }
        else {
          notification.error({
            message: 'Error Getting Data',
            description: message
          })
        }

      }

    } else {
      this.props.form.setFieldsValue({
        publishedDate: moment()
      })
    }


    let taskArr = await
      Request.getTaskList()
    let taskArray = _.map(taskArr, x => x.task)

    let sectionArray = _.uniq(_.map(taskArr, x => x.section))
    this.setState({ taskArr: taskArray, sectionArr: sectionArray })


  }

  render() {

    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props
      const {dispatch} = this.props

    const { editorState, options, testArray, tempTestArray, marksValue, taskArr, sectionArr } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }
    const handleChange = () => {

    }
    const inputTypes = {
      fields: [
        /*{
          label: 'Test Duration (in sec)',
          key: 'testDuration',
          required: true,
          type: 'number'
        },*/
        {
          label: 'Test Name',
          key: 'testName',
          required: true
        },
        {
          label: 'Test Date',
          key: 'testDate',
          required: true,
          type: 'date',
          render: (val, record) => {
            return (
              <div>
                {record.testDate
                  ? moment(record.testDate).format('YYYY-MM-DD')
                  : ''}
              </div>
            )
          }
        },
        {
          label: 'Price',
          key: 'price',
          required: true,
          type: 'number'
        },
        /*{
            label: 'SPKWR Time (in sec)',
            key: 'speakingWritingTime',
            type: 'number'
        },*/
        {
            label: 'Reading Time (in mins)',
          key: 'readingTime',
          type: 'number',
          required: true
        }
      ]
    }
    const inputTypes1 = {
      fields: [

        {
          label: 'Instructions',
          key: 'instructions',
          required: true,
          type: 'textArea',
          rows: 5
        },
        {
            label: 'Listening Time (in mins)',
          key: 'listeningTime',
          type: 'number',
          required: true
        },
        {
          label: 'Published Date',
          key: 'publishedDate',
          required: true,
          type: 'date',
          render: (val, record) => {
            return (
              <div>
                {record.publishedDate
                  ? moment(record.publishedDate).format('YYYY-MM-DD')
                  : ''}
              </div>
            )
          }
        }

      ]
    }
    const columns = [
      {
        title: 'Question',
        key: 'body',
        searchTextName: 'body',
        dataIndex: 'body',
        render: (u, record) => {
          return this.renderQuestion(record)
          // return <div className={styles.question}>{u ? S(u).stripTags().s : null}</div>
        }
      },
      {
        title: 'Type',
        dataIndex: 'taskName',
        key: 'taskName',
        filters: taskArr && taskArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.taskName ? record.taskName : ''}</div>

          )
        }

      },
      {
        title: 'Id',
        key: 'taskId',
        dataIndex: 'taskId',
        searchTextName: 'taskId'
      },
      {
        title: 'Section',
        dataIndex: 'section',
        key: 'section',
        filters: sectionArr && sectionArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.section ? record.section : ''}</div>

          )
        }

      },
      {
        title: 'Tags',
        width: 100,
        dataIndex: 'tags',
        key: 'tags',
        searchTextName: 'tags',
        render: cat => {
          return cat && cat.join(',')
        }
      },
      {
        key: 'actions',
        title: '',
        fixed: 'right',
        width: 50,
        render: (text, record) => {
          return <React.Fragment>
            <Tooltip title="Choose Question">
              <Button
                shape="circle"
                size='small'

                onClick={() => {

                  let x = [...testArray]


                  let dataItem = _.find(x, (item) => {
                    return item.questionId == record._id
                  })

                  if (!dataItem) {
                    x.push({
                      questionId: record._id,
                      taskId: record.taskId,
                      taskName: record.taskName,
                      body: record.body,
                      audio: record.audio,
                      questionImage: record.questionImage,
                      fibOptions: record.fibOptions,
                      mcqOptions: record.mcqOptions,
                      textArray: record.textArray,
                      reorderParagraph: record.reorderParagraph,
                      section: record.section,
                      marks: 1

                    })
                    this.setState({
                      testArray: x,
                      tempTestArray: x
                    })
                  }


                }}
                icon="forward"
              />
            </Tooltip>

          </React.Fragment>

        }
      }
    ]
    const columns1 = [
      {
        title: 'Question',

        searchTextName: 'body',
        render: (u, record) => {
          return this.renderQuestion(record)
          // return <div className={styles.question}>{u ? S(u).stripTags().s : null}</div>
        }
      },
      {
        title: 'Type',
        dataIndex: 'taskName',
        key: 'taskName',
        searchTextName: 'type',
        filters: taskArr && taskArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.taskName ? record.taskName : ''}</div>

          )
        }

      },
      {
        title: 'Id',
        key: 'taskId',
        dataIndex: 'taskId',
        searchTextName: 'taskId'
      },
      {
        title: 'Section',
        dataIndex: 'section',
        key: 'section',
        searchTextName: 'section',
        filters: sectionArr && sectionArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.section ? record.section : ''}</div>

          )
        }

      },
      {
        title: 'Marks',
        key: 'marks',
        type: 'number',
        render: (x, item) => {
          return <Input type='text' style={{ width: 50 }}
                        defaultValue={this.state.id ? this.defaultMarksUpdateTime(item) : 1}
                        onChange={(py) => {
                          let val = py.target.value
                          let x = [...testArray]
                          let ss = _.find(testArray, s => s.questionId == item.questionId)
                          ss.marks = val
                          this.setState({ testArray: x })
                        }}/>
        }
      },
      {
        key: 'actions',
        title: 'Actions', fixed: 'right',
        width: 50,
        render: (text, record) => {

          return <React.Fragment>
            <Tooltip title="Edit Question">
              <Button
                  shape="circle"
                  size="small"
                  onClick={() => {

                      if (typeof window !== 'undefined') {
                          let currentUrl = window.location.protocol + '//' + window.location.host
                          window.open(`${currentUrl}/question/edit-question?id=${record.questionId}`, '_blank')
                      }
                    /* dispatch(
                     getUrlPushWrapper('questions.editQuestion', {
                     id: record.questionId
                     })
                     )*/
                    /* window.open(`${apiUrl}/test/edit-test?id=${record.questionId}`, '_blank')
                     */

                  }}
                  icon="edit"
              />
            </Tooltip>
            <Tooltip title="Delete Question">
              <Button
                shape="circle"
                size="small"
                onClick={() => {
                  let x = [...testArray]

                  x = _.reject(x, (v) => {
                    return v.questionId == record.questionId
                  })
                  this.setState({
                    testArray: x
                  })


                }}
                icon="delete"
              />
            </Tooltip>

          </React.Fragment>

        }
      }
    ]


    if (!!this.state.id) {
      // inputTypes.fields = _.filter()
    }


    return (
      <PageHeaderWrapper
        title={this.state.id ? 'Update Test' : 'Add New Test'}
        content={''}>

        <Card bordered={true}>
          <Form
            key={this.state.key}
            onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

            <div>
              <Row>
                <Col span={10}>
                  <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator}/>

                </Col>
                <Col span={14}>
                  <GetAllFormFields inputSchema={inputTypes1} formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator}/>

                </Col>
              </Row>
            </div>


            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>

        <Card bordered={true}>
          <div>
            <Row>
              <Col span={6}>
                <h5>ENTER TAGS</h5>
                <Input
                  allowClear={true}
                  onChange={(py) => {
                    let x = py.target.value
                    this.setState({ tagData: x })


                  }}


                />

              </Col>
              <Col span={6}>
                <Button type={'primary'} style={{ marginTop: 23 }} onClick={async () => {
                  if (this.state && this.state.tagData) {
                    let params = {
                      tags: this.state.tagData,
                      results: 1000
                    }
                    let questionResp = await Request.getAllQuestions(params)
                    if (questionResp && questionResp.data && questionResp.data.length) {
                      let x = [...testArray]
                      _.each(questionResp.data, (item) => {
                        x.push({
                          questionId: item._id,
                          taskId: item.taskId,
                          taskName: item.taskName,
                          body: item.body,
                          audio: item.audio,
                          questionImage: item.questionImage,
                          fibOptions: item.fibOptions,
                          mcqOptions: item.mcqOptions,
                          textArray: item.textArray,
                          reorderParagraph: item.reorderParagraph,
                          section: item.section,
                          marks: 1
                        })

                      })

                      this.setState({
                        testArray: x,
                        tempTestArray: x
                      })

                    }
                  }


                }}>Search</Button>
              </Col>
            </Row>
          </div>
        </Card>

        <Card bordered={true}>
          <div>
            <Row>
              <Col span={12}>
                <h4 style={{ marginTop: 10 }}>{`Choose Questions (${this.state.totalCount})`}</h4>

                <TableComp ref={this.table} columns={columns}
                           extraProps={{ scroll: { x: 500 } }}
                           pagination={{
                             showSizeChanger: true,
                             defaultPageSize: 25,
                             pageSizeOptions: ['10', '20', '50', '100', '1000']
                           }}
                           apiRequest={(params) => this.apiRequest(params, columns)}/>

              </Col>
              <Col span={1}></Col>
              <Col span={11}>
                <h4 style={{ marginTop: 10 }}>{`Selected Questions (${this.state.testArray.length})`}</h4>

                <Table
                  showHeader={true}
                  dataSource={this.state.testArray ? this.state.testArray : []}
                  columns={columns1}
                  onChange={this.filterData}
                  pagination={false}
                  loading={this.state.loading}
                />

              </Col>
            </Row></div>


        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTest)
