import React, { Component } from 'react'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { showLoader, hideLoader } from '../../../modules/actions'

import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Select,
  Row, Col,
  notification,
  Switch,
  Input, Button, Icon,
  Popconfirm
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
import { Form } from 'antd/lib/form'

const Option = Select.Option

class AllStudents extends Component {

  // deleteUser = async data => {
  //   this.setState({ loading: true })
  //   await Request.deleteUser(data)
  //
  //   this.setState({ loading: false })
  //
  //   this.table.current.reload()
  //
  //   notification.success({
  //     message: 'Student deleted Successfully',
  //     duration: 20,
  //     key: `${data._id}-close`

  //   })
  // }


  constructor(props) {
    super(props)
    this.state = {
      studentType: '',
      studentArray: [],
      testName: '',
      testDate: '',
      testNumber: '',
      id: ''
    }
    this.table = React.createRef()

  }

  apiRequest = (params, columns) => {
    return new Promise(async (resolve) => {

      let regExFilters = _.map(columns, x => x.key)
      params.userType = ['Inhouse Student', 'Direct Student']


      if (this.state.studentType) {
        let { studentType } = this.state
        params.userType = studentType
      }
      let data = await Request.getAllStudents({ ...params, regExFilters })
      this.setState({ totalCount: data.total })

      resolve(data)

      // if (this.state && this.state.id) {
      //   console.log(this.state.id, 'nkjnjkjnjukhn')
      //
      // }

      /*  if (this.state && this.state.id) {
          console.log(this.state.id, 'popopppipip')
          let testId = this.state.id
          let data1 = {}
          let data = await Request.notBookedStudentsList({ testId })
          console.log(data)

          data1.data = data
          data1.count = data.length
          this.setState({ totalCount: data1.count })

          resolve(data1)
        }*/

      // let data1 = {}
      // let data = await Request.notBookedStudentsList({ testId: '5d14c0d2e307c53e802e928f' })
      // // console.log(data)
      // data1.data = data
      // data1.count = data.length
      // resolve(data)


    })
  }

  async componentDidMount() {

    let searchParams = new URLSearchParams(this.props.search)

    let testId = searchParams.get('id')
    if (testId) {
      Request.getTestById({ id: testId }).then(
        ({ data, error, message }) => {
          if (!error) {

            if (data.testDate) {
              data.testDate = moment(data.testDate).format('YYYY-MM-DD')
            }
            this.setState({
              id: data._id,
              testName: data.testName,
              testDate: data.testDate,
              testNumber: data.testNo

            })

          } else {
            notification.error({
              message: 'Error Getting Data',
              description: message
            })
          }
        }
      )
    }


  }


  render() {
    const { dispatch } = this.props
    const { studentArray } = this.state

    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email'
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile'
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address'
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        searchTextName: 'tags',
        render: (val) => {
          return <div>{val && val.join(',')}</div>
        }

      },
      {
        key: 'actions',
        title: 'Actions', fixed: 'right',
        width: 50,
        render: (text, record) => {

          return <React.Fragment>
            <Tooltip title="Assign Student">
              <Button
                shape="circle"
                size="small"

                onClick={() => {


                  let x = [...studentArray]

                  let dataItem = _.find(x, (item) => {
                    return item.studentId == record._id
                  })

                  if (!dataItem) {
                    x.push({
                      studentId: record._id,
                      name: record.name,
                      email: record.email,
                      mobile: record.mobile,
                      status: 'Assigned',
                      userType: record.userType
                    })
                    this.setState({
                      studentArray: x
                    })
                  }

                }}
                icon="forward"
              />
            </Tooltip>
          </React.Fragment>

        }
      }

    ]

    const columns1 = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email'
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile'
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        searchTextName: 'status'

      },
      {
        key: 'actions',
        title: 'Actions', fixed: 'right',
        width: 50,
        render: (text, record) => {

          return <React.Fragment>
            <Tooltip title="Remove Student">
              <Button
                shape="circle"
                size="small"
                onClick={() => {

                  let x = [...studentArray]

                  x = _.reject(x, (v) => {
                    return v.studentId == record.studentId
                  })
                  this.setState({
                    studentArray: x
                  })

                }}
                icon="close"
              />
            </Tooltip>
          </React.Fragment>

        }
      }

    ]
    return (
      <PageHeaderWrapper
        title={'Test Details'}

      >
        <card bordered={true} style={{ marginBottom: 10 }}>

          <div>
            <Row>
              <Col span={8}>
                <b>Test Name: {this.state.testName}</b>

              </Col>
              {/*<Col span={8}>
                  <b>Test Date: {this.state.testDate} </b>

                </Col>*/}

              <Col span={8}>
                <b>Test Number: {this.state.testNumber}</b>

              </Col>


            </Row>
          </div>

        </card>

        <card bordered={true}>
          <h3 style={{ paddingTop: 5 }}>
            SEARCH BY STUDENT TYPE
          </h3>
          <Select
            showSearch
            allowClear
            style={{
              width: 214,
              paddingBottom: 10
              // paddingRight: 16,
              // paddingLeft: 9
            }}
            value={this.state.studentType}
            placeholder="Select Student Type"
            onChange={studentType => {
              this.setState(
                { studentType: studentType && studentType.toString() },
                () => {
                  this.table.current.reload()
                }
              )
            }}>

            <Option value={'Inhouse Student'}>Inhouse Student</Option>
            <Option value={'Direct Student'}>Direct Student</Option>

          </Select>
        </card>
        <Card bordered={true}>
          <div>
            <Row>
              <Col span={12}>

                <h2>{`All Students (${this.state && this.state.totalCount})`}</h2>
                <TableComp ref={this.table}
                           columns={columns}
                           scroll={{ x: 2000 }}
                           apiRequest={(params) => this.apiRequest(params, columns)}/>

              </Col>
              <Col span={1}></Col>
              <Col span={11}>
                <h2>{`Assigned Students (${this.state && this.state.studentArray.length})`}</h2>


                <Table
                  showHeader={true}
                  dataSource={this.state.studentArray ? this.state.studentArray : []}
                  columns={columns1}
                  pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 1000,
                    pageSizeOptions: ['10', '20', '50', '100', '1000']
                  }}
                  loading={this.state.loading}
                />

              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="primary" htmlType="submit" loading={this.props.loading}
                        onClick={async () => {
                          let obj = {
                            testId: this.state.id,
                            studentTest: this.state.studentArray
                          }
                          dispatch(showLoader())
                          let x = await Request.addAssignedStudentTest(obj)
                          dispatch(hideLoader())
                          if (!x.error) {
                            notification.success({ message: x.message })
                            this.setState({ studentArray: '' })
                          }
                          else {
                            notification.error({ message: 'Error saving data' })
                          }

                        }}>
                  SAVE
                </Button>
              </Col>
            </Row>
          </div>

        </Card>


      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  search: router.location.search,
  loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllStudents)
