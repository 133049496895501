import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import StateJson from '../../../state'

import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'

// inputSchema.fields


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddUser extends PureComponent {

  state = {
    key: Math.random() * 1000
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)

        dispatch(showLoader())

        let x = null
        if (this.state.id) {
          values._id = this.state.id
          x = await Request.updateUser(values)
        } else {
          x = await Request.addUser(values)
        }

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: this.state.id ? 'Student updated successfully' : 'Student added successfully'
          })

          if (!this.state.id) {
            this.props.form.setFieldsValue({})
            form.resetFields()
          }
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }


  constructor(props) {
    super(props)
    this.state = {
      options: []
    }


  }

  async componentDidMount() {

    let searchParams = new URLSearchParams(this.props.search)

    let userId = searchParams.get('id')

    if (userId) {
      Request.getUserById({ id: userId }).then(
        ({ data, error, message }) => {
          if (!error) {

            if (data.dob) {
              data.dob = moment(data.dob)
            }

              if (data.deactivationDate) {
                  data.deactivationDate = moment(data.deactivationDate)
              }

            this.setState({
              id: data._id,
              key: Math.random() & 100
            })

            this.props.form.setFieldsValue({
              address: data.address,
              country: data.country,
              dob: data.dob ? data.dob : null,
              email: data.email,
              gender: data.gender,
              mobile: data.mobile,
              name: data.name,
              postcode: data.postcode,
              state: data.state,
              tags: data.tags,
              userType: data.userType,
                city: data.city,
                deactivationDate: data.deactivationDate ? data.deactivationDate : null,
            })


          } else {
            notification.error({
              message: 'Error Getting Data',
              description: message
            })
          }
        }
      )
    }
  }

  render() {

    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const { editorState, options } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }
    const handleChange = () => {

    }
    const inputTypes = {
      fields: [
        {
          label: 'Full Name',
          key: 'name',
        },
        {
          label: 'Mobile',
          key: 'mobile',
        },
        {
          label: 'DOB',
          key: 'dob',
          type: 'date',
          render: (val, record) => {
            return (
              <div>
                {record.dob
                  ? moment(record.dob).format('DD-MMM-YYYY')
                  : ''}
              </div>
            )
          }
        },
        {
          label: 'Address',
          key: 'address',
          type: 'textArea',
          rows: 5
        },
        {
          label: 'Country',
          key: 'country',
          type: 'select',
            showSearch: true,
          keyAccessor: x => x.country,
          valueAccessor: x => {
            return x.country
          },
          options: StateJson,
          onChange: country => {
            let x = _.find(StateJson, y => y.country == country)
            if (x) {
              this.setState({
                statesJson: x.states
              })
            }
            setFieldsValue({ country: country && country.toString(), state: null })
          }
        },
        {
          label: 'State',
          key: 'state',
          type: 'select',
            showSearch: true,

            disabled: !!this.state.id ? false : !this.state.statesJson,
          keyAccessor: x => x.states,
          valueAccessor: x => {
            return x.states
          },
          options: this.state.statesJson || [],
          onChange: state => {
            setFieldsValue({ state: state && state.toString() })
          }
        },
        {
          label: 'City',
          key: 'city',
        },
        {
          label: 'Postcode',
          key: 'postcode',
        },
        {
          label: 'Gender',
          key: 'gender',
          disabled: false,
          allowClear: true,
          type: 'select', options: ['Male', 'Female'],
          onChange: gender => {
            setFieldsValue({ gender: gender && gender.toString() })
          }

        },
        {
          label: 'Email',
          key: 'email',
          required: true
        },
        {
          label: 'Password',
          key: 'password',
          required: true,
          type: 'password',
          hidden: !!this.state.id
        },
        {
          label: 'User Type',
          key: 'userType',
          required: true,
          disabled: false,
          allowClear: true,
          type: 'select', options: ['Admin', 'Direct Student', 'Inhouse Student'],
          onChange: userType => {
            setFieldsValue({ userType: userType && userType.toString() })
          }
        },
        {
          label: 'tags',
          key: 'tags', type: 'select', mode: 'tags',
          options, onChange: (dd) => {
            setFieldsValue({
              tags: dd
            })
          }
        },
          {
              label: 'Deactivation Date',
              key: 'deactivationDate',
              type: 'date',


          },
      ]
    }

    if (!!this.state.id) {
      // inputTypes.fields = _.filter()
    }


    return (
      <PageHeaderWrapper
        title={this.state.id ? 'Update Student/Admin' : 'Add New Student/Admin'}
      >

        <Card bordered={true}>
          <Form
            key={this.state.key}
            onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

            <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser)
