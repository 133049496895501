import React, {PureComponent} from 'react'

import {notification, Row, Col, Form, Button, Card, Select} from 'antd'
import Request from '../../request'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
// import GetAllFormFields from '../../components/_utils/formUtils'

import PageHeaderWrapper from './addQuestions'
import _ from 'lodash'
import {API_URL} from '../../request'
import {connect} from 'react-redux'
import moment from 'moment/moment'

@Form.create()

class SpeakingWriting extends React.Component {

    state = {
        init: ''


    }
    handleClick = e => {
    }
    sendBack = async level => {
        let {rollBackLevel} = this.props
        if (level) {
            rollBackLevel = level
        }

        let x = await Request.changeDesk({
            _id: this.props.data._id,
            atLevel: rollBackLevel
        })

        if (x.error) {
        } else {
            if (!level) {
                this.props.done()

                notification.success({
                    message: `Student Moved To ${rollBackLevel}`
                })

            } else {
                let x = await Request.changeDesk({
                    _id: this.props.data._id,
                    atLevel: 'Dead Inquiry'
                })
                this.props.done()
                notification.success({
                    message: 'Student Inquiry Dead'
                })

            }
        }
    }
    handle1 = (e) => {
        const {dispatch, form} = this.props
        e.preventDefault()
        console.log(form)
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                this.props.setSample(values, (resp) => {
                    if (resp.type && !resp.id) {
                        form.resetFields()

                    }
                })

            }
        })
    }

    renderTask = (taskId) => {

        const {form: {getFieldDecorator, getFieldValue}} = this.props
        const formProps = {getFieldDecorator, getFieldValue}

        const myState = {
            ReadAloud: {
                fields: [
                    {
                      label: 'Question Body', type: 'editor',
                        customDirectives: {
                            initialValue: ''
                        },
                        key: 'body',
                        required: true
                    },
                    {
                        label: 'Preparation Duration (in seconds)',
                        key: 'preparationDuration',
                        //  customDirectives: {initialValue: 20},
                        required: true
                    },
                    /* {
                       label: 'Play Tone', type: 'switch', key: 'playTone', onChange: (v) => {
                         this.props.form.setFieldsValue({
                           playTone: v
                         })
                       }
                     },*/
                    {
                        label: 'Recording Duration (in seconds)',
                        type: 'number',
                        required: true,
                        key: 'recordingDuration',
                        // customDirectives: {initialValue: 30},
                    },
                    {
                        key: 'tags', type: 'select', mode: 'tags',
                        options: []
                        , onChange: (dd) => {
                            this.props.form.setFieldsValue({
                                tags: dd
                            })
                        }
                    }
                ]
            },
            RepeatSentence: {
                fields: [
                    {
                        label: 'Audio File', type: 'file', key: 'audio', required: true
                    },
                    {
                        label: 'Preparation Duration (in seconds)',
                        key: 'preparationDuration',
                        required: true,
                        type: 'number',
                        // customDirectives: {initialValue: 0},

                        // defaultValue: 2

                    },
                    {
                        label: 'Recording Duration (in seconds)',
                        required: true,
                        key: 'recordingDuration',
                        type: 'number'
                        /*defaultValue: 10,
                        onChange: ((val) => {
                          this.props.form.setFieldsValue({ recordingDuration: val })
                        })*/
                    },
                    {
                        key: 'tags', type: 'select', mode: 'tags',
                        options: []
                        , onChange: (dd) => {
                            this.props.form.setFieldsValue({
                                tags: dd
                            })
                        }
                    }
                ]
            },
            DescribeImage: {
                fields: [
                    {label: 'Image File', type: 'file', key: 'questionImage', required: true},
                    {
                        label: 'Preparation Duration (in seconds)',
                        type: 'number',
                        required: true,
                        key: 'preparationDuration'
                        // defaultValue: 25,

                    },
                    {
                        label: 'Recording Duration (in seconds)',
                        type: 'number',
                        required: true,
                        // defaultValue: 40,
                        key: 'recordingDuration'
                    },
                    /* {
                       label: 'Play Tone', type: 'switch', key: 'playTone',
                       onChange: (v) => {
                         this.props.form.setFieldsValue({
                           playTone: v
                         })
                       }
                     },*/
                    {
                        key: 'tags', type: 'select', mode: 'tags',
                        options: []
                        , onChange: (dd) => {
                            this.props.form.setFieldsValue({
                                tags: dd
                            })
                        }
                    }
                ]
            },
            RetellLecture: {
                fields: [
                    {
                        label: 'Audio File', type: 'file', key: 'audio', required: true
                    },
                    {label: 'Image File', type: 'file', key: 'questionImage'},
                    {
                        label: 'Preparation Duration (in seconds)',
                        type: 'number',
                        key: 'preparationDuration',
                        required: true
                        // defaultValue: 10,

                    },
                    /* {
                       label: 'Play Tone', type: 'switch', key: 'playTone', onChange: (v) => {
                         this.props.form.setFieldsValue({
                           playTone: v
                         })
                       }
                     },*/
                    {
                        label: 'Recording Duration (in seconds)',
                        type: 'number',
                        required: true,
                        key: 'recordingDuration'
                        // defaultValue: 40,
                    },
                    {
                        key: 'tags', type: 'select', mode: 'tags',
                        options: []
                        , onChange: (dd) => {
                            this.props.form.setFieldsValue({
                                tags: dd
                            })
                        }
                    }

                ]
            },
            AnswerShortQuestion: {
                fields: [

                    {label: 'Audio File', type: 'file', key: 'audio', required: true},
                    {
                        label: 'Recording Duration (in seconds)',
                        type: 'number',
                        required: true,
                        key: 'recordingDuration'
                        // defaultValue: 10,

                    },
                    {
                        key: 'tags', type: 'select', mode: 'tags',
                        options: []
                        , onChange: (dd) => {
                            this.props.form.setFieldsValue({
                                tags: dd
                            })
                        }
                    }

                ]
            },
            SummarizeWrittenText: {
                fields: [
                    {
                      label: 'Question Body', type: 'editor', key: 'body', required: true, customDirectives: {
                            initialValue: ''
                        }
                    },
                    {label: 'Answer Duration (in seconds)', type: 'number', key: 'answerDuration', required: true},
                    {
                        key: 'tags', type: 'select', mode: 'tags',
                        options: []
                        , onChange: (dd) => {
                            this.props.form.setFieldsValue({
                                tags: dd
                            })
                        }
                    }

                ]
            },
            Essay: {
                fields: [
                    {
                      label: 'Question Body', type: 'editor', key: 'body', required: true, customDirectives: {
                            initialValue: ''
                        }
                    },
                    {label: 'Answer Duration (in seconds)', type: 'number', key: 'answerDuration', required: true},
                    {
                        key: 'tags', type: 'select', mode: 'tags',
                        options: []
                        , onChange: (dd) => {
                            this.props.form.setFieldsValue({
                                tags: dd
                            })
                        }
                    }
                ]
            }

        }


        const {ReadAloud, RepeatSentence, DescribeImage, RetellLecture, AnswerShortQuestion, SummarizeWrittenText, Essay} = myState

        switch (taskId) {
            case 1:
                return <div>
                    <Form onSubmit={this.handle1}>
                        <GetAllFormFields
                            inputSchema={ReadAloud}
                            {...formProps}
                        />

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10}}
                                htmlType="submit"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 2:
                return <div>
                    <Form onSubmit={this.handle1}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={RepeatSentence}
                            {...formProps}
                        />

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10}}
                                htmlType="submit"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 3:
                return <div>
                    <Form onSubmit={this.handle1}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={DescribeImage}
                            {...formProps}
                        />

                        <Row>
                            <Col span={8}>

                            </Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10}}
                                htmlType="submit"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 4:
                return <div>
                    <Form onSubmit={this.handle1}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={RetellLecture}
                            {...formProps}
                        />

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10}}
                                htmlType="submit"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 5 || 6:
                return <div>
                    <Form onSubmit={this.handle1}>

                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={AnswerShortQuestion}
                            {...formProps}
                        />

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10}}
                                htmlType="submit"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>

            case 6:
                return <div>
                    <Form onSubmit={this.handle1}>

                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={SummarizeWrittenText}
                            {...formProps}
                        />

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10}}
                                htmlType="submit"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 7:
                return <div>
                    <Form onSubmit={this.handle1}>

                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={Essay}
                            {...formProps}
                        />

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10}}
                                htmlType="submit"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>

            default:
                return null

        }
    }

    componentDidMount() {

        let {data} = this.props.values

        if (data) {

            let {data} = this.props.values
            this.props.form.setFieldsValue({
                body: data && data.body,
                preparationDuration: data && data.preparationDuration,
                recordingDuration: data && data.recordingDuration,
                answerDuration: data && data.answerDuration,
                tags: data && data.tags,
                audio: data && data.audio,
                questionImage: data && data.questionImage,
                playTone: data && data.playTone
            })
        }

    }

    render() {
        let {taskId, taskName, values} = this.props

        return (
            <div>
                {taskName}
                {this.renderTask(taskId)}
            </div>

        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    bodyText: global.bodyText
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpeakingWriting)
