import React, { Component } from 'react'

// import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/index'

import { showLoader, hideLoader } from '../../../modules/actions'
import { apiUrl } from '../../../settings'

import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon,
  Popconfirm
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'

class AllBookedTests extends Component {
  state = {
    loginUser: {}
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()

  }


  componentDidMount() {
    let loginUser = localStorage.getItem('user')
    if (loginUser) {
      loginUser = JSON.parse(loginUser)
    }
    this.setState({ loginUser })
  }

  renderOnPage(record) {
    const { dispatch } = this.props
    console.log()
    if (this.state.loginUser && this.state.loginUser.pteUser) {

      dispatch(
        getUrlPushWrapper('bookedTest.checkTestPte', {
          id: record._id
        })
      )
    } else {

      dispatch(
        getUrlPushWrapper('bookedTest.checkTest', {
          id: record._id
        })
      )
    }
  }


  apiRequest = (params, columns) => {
    return new Promise(async (resolve) => {
      let regExFilters = _.map(columns, x => x.key)
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let data = await Request.getAllBookedTests({ ...params, regExFilters })
      resolve(data)
    })
  }


  renderStatus = (val, submitTime) => {


    if (val === 'Pending') {
      return <div className={styles.pending}>{val.toUpperCase()}</div>
    }
    if (val === 'Assigned') {
      return <div className={styles.assigned}>{val.toUpperCase()}</div>
    }

    if (val === 'Paid') {
      return <div className={styles.paid}>{val.toUpperCase()}</div>
    }

    if (val === 'Canceled') {
      return <div className={styles.canceled}>{val.toUpperCase()}</div>
    }

    if (val === 'Not Attempted') {
      return <div className={styles.notAttempted}>{val.toUpperCase()}</div>
    }

    if (val === 'Attempted') {
      return <div className={styles.attempted}>{val.toUpperCase()}</div>
    }

    if (val === 'Checked') {
      return <div className={styles.checked}>{val.toUpperCase()}</div>
    }
    if (val === 'Submitted') {
      return <div className={styles.submitted}>{val.toUpperCase()}
        {submitTime ? <div>({submitTime})</div> : ''}
      </div>
    }
  }


  async deleteBookedTest(data) {
    let resp = await Request.deleteBookedTest(data)
    if (resp.success) {
      this.table.current.reload()
      notification.success({
        message: resp.message,
        duration: 20
      })
    } else {
      notification.error({
        message: resp.message,
        duration: 20
      })
    }

  }


  render() {
    const { dispatch } = this.props

    const columns = [
      {
        title: 'Test Number',
        dataIndex: 'testNo',
        key: 'testNo',
        searchTextName: 'testNo'
      },
      /*{
        title: 'Test Date',
        dataIndex: 'testDate',
        key: 'testDate',
        searchTextName: 'testDate',
        render: (val, record) => {
          return (
            <div>
              {record.testDate
                ? moment(record.testDate).format('DD-MMM-YYYY')
                : ''}
            </div>
          )
        }
      },*/
      {
        title: 'Test Name',
        key: 'testName',
        width: 100,
        dataIndex: 'testName',
        searchTextName: 'test Name'
      },
      {
        title: 'Student Name',
        key: 'studentName',
        width: 100,
        dataIndex: 'studentName',
        searchTextName: 'studentName'
      },
      {
        title: 'Student Type',
        key: 'studentType',
        width: 100,
        dataIndex: 'studentType',
        searchTextName: 'studentType'
      },
      {
        title: 'Price',
        dataIndex: 'payment.paidAmount',
        key: 'payment.paidAmount',
        searchTextName: 'payment',
        render: (val) => {
          return <div>
            Rs {val}
          </div>
        }

      },
      {
        title: 'Payment',
        dataIndex: 'payment.status',
        key: 'payment.status',
        searchTextName: 'payment',

        render: (val, record) => {
          return this.renderStatus(val)

        }

      },
      {
        title: 'Test Status',
        dataIndex: 'testStatus',
        key: 'testStatus',
        searchTextName: 'testStatus',
        render: (val, record) => {

          return this.renderStatus(val, record.submitTime)

        }
      },
      {
        title: 'Booked Test Date',
        dataIndex: 'createdByUser',
        key: 'createdByUser',
        searchTextName: 'date',
        render: (val, record) => {
          return (
            <div>
              <div>
                {val
                  ? val.name
                  : ''}
              </div>
              <small>
                {val
                  ? moment(val.time).format('lll')
                  : ''}
              </small>


            </div>
          )
        }
      },
      {
        key: 'actions',
        title: 'Actions', fixed: 'right',
        width: 100,
        render: (text, record) => {
          let condtition = text.payment.status === 'Pending' || text.testStatus === 'Not Attempted'
          let condition1 = text.payment.status === 'Paid' || text.testStatus === 'Attempted' || text.testStatus == 'Submitted' || text.testStatus == 'Checked'

          // let condtition = !((text.payment.status === 'Pending' || text.testStatus === 'Not Attempted') && text.testStatus === 'Attempted')

          return <React.Fragment>
            <Tooltip title="Check Now">
              {condition1 &&
              <Button
                shape="circle"
                onClick={(v) => {
                  this.renderOnPage(record)

                }}
                icon="check"
              />}

            </Tooltip>
            <Tooltip title="Delete Test">
              {/*{condtition}*/}

              <Popconfirm
                title={`Are you sure, you want to delete this test?`}
                onConfirm={() => {
                  this.deleteBookedTest(record)
                }}
                onCancel={() => {

                }}
                okText="Yes"
                cancelText="No">
                <Button
                  className={styles.btn} shape="circle"
                  icon="delete"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="View Result">

              {text.testStatus === 'Checked' && <Button
                shape="circle"
                onClick={() => {
                  window.open(`${apiUrl}/student/result/${record._id}`, '_blank')

                }}
                icon="eye"
              />}

            </Tooltip>
          </React.Fragment>

        }
      }

    ]
    return (
      <PageHeaderWrapper
        title={'All Booked Tests'}>

        <Card bordered={true}>
          <TableComp ref={this.table} columns={columns}

                     apiRequest={(params) => this.apiRequest(params, columns)}/>
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  search: router.location.search,
  loading: global.buttonLoading


})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllBookedTests)
