import React, { PureComponent } from 'react'

import { notification, Row, Col, Form, Button, Tooltip, Card, Input, Checkbox, Table } from 'antd'
import Request from '../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import PageHeaderWrapper from './addQuestions'
import _ from 'lodash'
import { API_URL } from '../../request'
import { connect } from 'react-redux'

@Form.create()

class Listening extends React.Component {

  state = {
    text: '',
    checked: false,
    option: '',
    allTextArea: [],
    allAnswers: [],
    allWords: [],
    allOptions: [],
    inputText: '',
    summarizeSpokenText: {
      tabTitle: 'General Info',
      fields: [

        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        { label: 'Answer Duration (in seconds)', type: 'number', key: 'answerDuration', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }
      ]
    },
    multipleChoiceMultipleAnswer: {
      tabTitle: 'General Info',
      fields: [
        {
          label: 'Question Body', type: 'editor', key: 'body', required: true, customDirectives: {
            initialValue: ''
          }
        },
        {
          label: 'Question', type: 'editor', key: 'mcqBody', required: true, customDirectives: {
            initialValue: ''
          }
        },
        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }

      ]
    },
    writeFromDictation: {
      tabTitle: 'General Info',
      fields: [
        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }
      ]
    },
    fillInTheBlanksListening: {
      tabTitle: 'General Info',
      fields: [
        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }
      ]
    },
    highlightCorrectSummary: {
      tabTitle: 'General Info',
      fields: [

        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }
      ]
    },
    multipleChoiceSingleAnswer: {
      tabTitle: 'General Info',
      fields: [
        {
          label: 'Question Body', type: 'editor', key: 'body', required: true, customDirectives: {
            initialValue: ''
          }
        },
        {
          label: 'Question Body', type: 'editor', key: 'mcqBody', required: true, customDirectives: {
            initialValue: ''
          }
        },
        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }
      ]
    },
    selectMissingWord: {
      tabTitle: 'General Info',
      fields: [

        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }
      ]
    },

    highlightIncorrectWords: {
      tabTitle: 'General Info',
      fields: [
        {
          label: 'Question Body', type: 'editor', key: 'body', required: true, customDirectives: {
            initialValue: ''
          }
        },
        { label: 'Audio File', type: 'file', key: 'audio', required: true },
        {
          key: 'tags', type: 'select', mode: 'tags',
          options: []
          , onChange: (dd) => {
            this.props.form.setFieldsValue({
              tags: dd
            })
          }
        }
      ]
    }


  }
  handleClick = e => {
  }
  sendBack = async level => {
    let { rollBackLevel } = this.props
    if (level) {
      rollBackLevel = level
    }

    let x = await Request.changeDesk({
      _id: this.props.data._id,
      atLevel: rollBackLevel
    })

    if (x.error) {
    } else {
      if (!level) {
        this.props.done()

        notification.success({
          message: `Student Moved To ${rollBackLevel}`
        })

      } else {
        let x = await Request.changeDesk({
          _id: this.props.data._id,
          atLevel: 'Dead Inquiry'
        })
        this.props.done()
        notification.success({
          message: 'Student Inquiry Dead'
        })

      }
    }
  }
  handle1 = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)
        values.mcqOptions = this.state.allAnswers
        values.reorderParagraph = this.state.allOptions
        values.textArray = this.state.allTextArea

        this.props.setSample(values, (resp) => {
          if (resp.type && !resp.id) {
            form.resetFields()

          }
        })
        // form.resetFields()
        this.setState({
          allAnswers: [],
          allOptions: [],
          allTextArea: []
        })
      }
    })
  }
  renderTask = (taskId) => {

    const { form: { getFieldDecorator, getFieldValue } } = this.props
    const formProps = { getFieldDecorator, getFieldValue }
    const columns1 = [
      {
        title: 'Option',
        key: 'value',
        dataIndex: 'value',

        searchTextName: '',
        filterRegex: true
      },
      {
        title: 'Correct',
        key: 'correct',
        dataIndex: 'correct',
        render: (x) => {
          return <span>{x ? 'YES' : 'NO'}</span>
        }
      },

      {
        title: 'Action',
        key: 'operation',
        icon: 'trash',

        render: (val, record) => (
          <React.Fragment>
            <Tooltip title="Delete">
              <Button
                shape="circle"
                onClick={() => {
                  let tt = this.state.allAnswers
                  tt = _.reject(tt, val)
                  this.setState({ allAnswers: tt })
                }}
                icon="delete"
                size={'small'}
              />
            </Tooltip>
          </React.Fragment>
        )
      }
    ]
    const columns2 = [
      {
        title: 'Option',
        key: 'value',
        dataIndex: 'value',
        searchTextName: '',
        filterRegex: true
      },
      // {
      //     title: 'Order',
      //     key: 'orderNo',
      //     dataIndex: 'orderNo'
      // },

      {
        title: 'Action',
        key: 'operation',
        icon: 'trash',

        render: (val, record) => (
          <React.Fragment>
            <Tooltip title="Delete">
              <Button
                shape="circle"
                onClick={() => {
                  let tt = this.state.allOptions
                  tt = _.reject(tt, val)
                  this.setState({ allOptions: tt })
                }}
                icon="delete"
                size={'small'}
              />
            </Tooltip>
          </React.Fragment>
        )
      }
    ]
    const columns3 = [
      {
        title: 'Text',
        key: 'text',
        dataIndex: 'text',

        searchTextName: '',
        filterRegex: true
      },

      {
        title: 'Action',
        key: 'operation',
        icon: 'trash',

        render: (val, record) => (
          <React.Fragment>
            <Tooltip title="Delete">
              <Button
                shape="circle"
                onClick={() => {
                  let tt = this.state.allTextArea
                  tt = _.reject(tt, val)
                  this.setState({ allTextArea: tt })
                }}
                icon="delete"
                size={'small'}
              />
            </Tooltip>
          </React.Fragment>
        )
      }
    ]

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 }
      }
    }


    const {
      summarizeSpokenText, multipleChoiceMultipleAnswer, writeFromDictation, allAnswers, fillInTheBlanksListening,
      highlightCorrectSummary, multipleChoiceSingleAnswer, selectMissingWord, highlightIncorrectWords, allWords, allOptions, allTextArea
    } = this.state

    switch (taskId) {
      case 13:
        return <div>
          <Form onSubmit={this.handle1}>

            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={summarizeSpokenText}
              {...formProps}
            />

            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>
      case 14:
        return <div>
          <Form onSubmit={this.handle1} {...formItemLayout}>

            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={multipleChoiceMultipleAnswer}
              {...formProps}
            />
            <div>
              <Card bordered={true}>
                <Row>
                  <Col span={15}>

                    <Form.Item label={'Answer'}
                    >
                      <Input
                        value={this.state.text}
                        onChange={py => {
                          py = py.target.value
                          this.setState({ text: py })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={4}>
                    <Form.Item label={'Check'}>
                      <Checkbox
                        checked={this.state.checked}
                        loading={true}
                        onChange={y => {
                          this.setState({ checked: y.target.checked })
                        }}>
                      </Checkbox>
                    </Form.Item>

                  </Col>
                  <Col span={4}>

                    <Form.Item>
                      <Button
                        style={{ marginBottom: 10, marginTop: 10 }}
                        size={'small'}
                        onClick={() => {
                          let { text, allAnswers, checked } = this.state
                          let n = [...allAnswers]
                          n.push({ value: text, correct: checked })
                          this.setState({
                            allAnswers: n
                          })
                          this.setState({
                            text: null,
                            checked: false
                          })
                        }}>
                        ADD
                      </Button>
                    </Form.Item>


                  </Col>
                </Row>
                <Row>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns1}
                    dataSource={allAnswers}
                  />

                </Row>
              </Card>
            </div>


            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>
      case 15:
        return <div>
          <Form {...formItemLayout} onSubmit={this.handle1}>
            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={fillInTheBlanksListening}
              {...formProps}
            />
            <div>
              <Card bordered={true}>
                <Row>
                  <Col span={16}>

                    <Form.Item label={'Add Text'}
                    >
                      <Input
                        value={this.state.text}
                        onChange={py => {
                          py = py.target.value
                          this.setState({ text: py })
                        }}
                      />
                    </Form.Item>

                  </Col>
                  <Col span={8}> <Form.Item>
                    <Button
                      style={{ marginBottom: 10, marginTop: 5, marginLeft: 46 }}
                      size={'small'}
                      onClick={() => {
                        let { text, allTextArea } = this.state
                        let n = [...allTextArea]
                        n.push({ text: text })
                        this.setState({
                          allTextArea: n
                        })
                        this.setState({
                          text: null
                        })
                      }}>
                      ADD
                    </Button>
                  </Form.Item>
                  </Col>


                </Row>
                <Row>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns3}
                    dataSource={allTextArea}
                  />
                </Row>
              </Card>


              <Card bordered={true}>
                <Row>
                  <Col span={16}>

                    <Form.Item label={'Enter Option'}
                    >
                      <Input
                        value={this.state.inputText}
                        onChange={py => {
                          py = py.target.value
                          this.setState({ inputText: py })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/*
                                    <Col span={4}>
                                        <Form.Item label={'Order'}>
                                            <Input
                                                value={this.state.option}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({option: py})
                                                }}
                                            />
                                        </Form.Item>

                                    </Col>
*/}
                  <Col span={4}>

                    <Form.Item>
                      <Button
                        style={{ marginLeft: 10 }}
                        size={'small'}
                        onClick={() => {
                          let { inputText, allOptions } = this.state
                          let n = [...allOptions]
                          n.push({ value: inputText })
                          this.setState({
                            allOptions: n
                          })
                          this.setState({
                            inputText: null
                          })
                        }}>
                        ADD
                      </Button>
                    </Form.Item>


                  </Col>
                </Row>
                <Row>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns2}
                    dataSource={allOptions}
                  />

                </Row>
              </Card>

            </div>

            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>
      case 16:
        return <div>
          <Form onSubmit={this.handle1} {...formItemLayout}>

            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={highlightCorrectSummary}
              {...formProps}
            />
            <div>
              <Card bordered={true}>
                <Row>
                  <Col span={15}>

                    <Form.Item label={'Answer'}
                    >
                      <Input
                        value={this.state.text}
                        onChange={py => {
                          py = py.target.value
                          this.setState({ text: py })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={4}>
                    <Form.Item label={'Check'}>
                      <Checkbox
                        checked={this.state.checked}
                        loading={true}
                        onChange={y => {
                          this.setState({ checked: y.target.checked })
                        }}>
                      </Checkbox>
                    </Form.Item>

                  </Col>
                  <Col span={4}>

                    <Form.Item>
                      <Button
                        style={{ marginBottom: 10, marginTop: 10 }}
                        size={'small'}
                        onClick={() => {
                          let { text, allAnswers, checked } = this.state
                          let n = [...allAnswers]
                          n.push({ value: text, correct: checked })
                          this.setState({
                            allAnswers: n
                          })
                          this.setState({
                            text: null,
                            checked: false
                          })
                        }}>
                        ADD
                      </Button>
                    </Form.Item>


                  </Col>
                </Row>
                <Row>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns1}
                    dataSource={allAnswers}
                  />

                </Row>
              </Card>
            </div>


            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>
      case 17:
        return <div>
          <Form onSubmit={this.handle1} {...formItemLayout}>

            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={multipleChoiceSingleAnswer}
              {...formProps}
            />
            <div>
              <Card bordered={true}>
                <Row>
                  <Col span={15}>

                    <Form.Item label={'Answer'}
                    >
                      <Input
                        value={this.state.text}
                        onChange={py => {
                          py = py.target.value
                          this.setState({ text: py })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>

                  <Col span={4}>
                    <Form.Item label={'Check'}>
                      <Checkbox
                        checked={this.state.checked}
                        loading={true}
                        onChange={y => {
                          this.setState({ checked: y.target.checked })
                        }}>
                      </Checkbox>
                    </Form.Item>

                  </Col>
                  <Col span={4}>

                    <Form.Item>
                      <Button
                        style={{ marginBottom: 10, marginTop: 10 }}
                        size={'small'}
                        onClick={() => {
                          let { text, allAnswers, checked } = this.state
                          let n = [...allAnswers]
                          n.push({ value: text, correct: checked })
                          this.setState({
                            allAnswers: n
                          })
                          this.setState({
                            text: null,
                            checked: false
                          })
                        }}>
                        ADD
                      </Button>
                    </Form.Item>


                  </Col>
                </Row>
                <Row>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns1}
                    dataSource={allAnswers}
                  />

                </Row>
              </Card>
            </div>


            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>
      case 18:
        return <div>
          <Form onSubmit={this.handle1} {...formItemLayout}>

            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={selectMissingWord}
              {...formProps}
            />
            <div>
              <Card bordered={true}>
                <Row>
                  <Col span={15}>

                    <Form.Item label={'Answer'}
                    >
                      <Input
                        value={this.state.text}
                        style={{ marginRight: 20 }}
                        onChange={py => {
                          py = py.target.value
                          this.setState({ text: py })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>

                  <Col span={4}>
                    <Form.Item label={'Check'}>
                      <Checkbox
                        checked={this.state.checked}
                        loading={true}
                        onChange={y => {
                          this.setState({ checked: y.target.checked })
                        }}>
                      </Checkbox>
                    </Form.Item>

                  </Col>
                  <Col span={4}>

                    <Form.Item>
                      <Button
                        style={{ marginBottom: 10, marginTop: 10 }}
                        size={'small'}
                        onClick={() => {
                          let { text, allAnswers, checked } = this.state
                          let n = [...allAnswers]
                          n.push({ value: text, correct: checked })
                          this.setState({
                            allAnswers: n
                          })
                          this.setState({
                            text: null,
                            checked: false
                          })
                        }}>
                        ADD
                      </Button>
                    </Form.Item>


                  </Col>
                </Row>
                <Row>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns1}
                    dataSource={allAnswers}
                  />

                </Row>
              </Card>
            </div>


            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>
      case 19:
        return <div>
          <Form onSubmit={this.handle1} {...formItemLayout}>

            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={highlightIncorrectWords}
              {...formProps}
            />
            <div>
              <Card bordered={true}>
                <Row>
                  <Col span={15}>

                    <Form.Item label={'Answer'}
                    >
                      <Input
                        value={this.state.text}
                        onChange={py => {
                          py = py.target.value
                          this.setState({ text: py })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={4}>
                    <Form.Item label={'Check'}>
                      <Checkbox
                        checked={this.state.checked}
                        loading={true}
                        onChange={y => {
                          this.setState({ checked: y.target.checked })
                        }}>
                      </Checkbox>
                    </Form.Item>

                  </Col>
                  <Col span={4}>

                    <Form.Item>
                      <Button
                        style={{ marginBottom: 10, marginTop: 10 }}
                        size={'small'}
                        onClick={() => {
                          let { text, allAnswers, checked } = this.state
                          let n = [...allAnswers]
                          n.push({ value: text, correct: checked })
                          this.setState({
                            allAnswers: n
                          })
                          this.setState({
                            text: null,
                            checked: false
                          })
                        }}>
                        ADD
                      </Button>
                    </Form.Item>


                  </Col>
                </Row>
                <Row>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns1}
                    dataSource={allAnswers}
                  />

                </Row>
              </Card>
            </div>


            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>

      case 20:
        return <div>
          <Form onSubmit={this.handle1}>

            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={writeFromDictation}
              {...formProps}
            />

            <Row>
              <Col span={8}></Col>
              <Col span={16}> <Button
                type="primary"
                style={{ marginBottom: 10, marginTop: 15 }}
                htmlType="SAVE"
                loading={this.props.loading}>
                SAVE
              </Button></Col>
            </Row>

          </Form>
        </div>


      default:
        return null

    }
  }

  componentDidMount() {
    if (this.props.values) {
      let { data } = this.props.values
      if (data) {
        let text = []
        _.each(data.textArray, (item) => {
          let abc = {}
          abc.text = item
          text.push(abc)
        })
        this.props.form.setFieldsValue({
          body: data && data.body,
          mcqBody: data && data.mcqBody,
          // preparationDuration: data && data.preparationDuration,
          answerDuration: data && data.answerDuration,
          tags: data && data.tags,
          audio: data && data.audio
        })
        this.setState({
          allTextArea: text && text,
          fibOptions: data && data.fibOptions,
          allAnswers: data && data.mcqOptions,
          allOptions: data && data.reorderParagraph

        })

      }


    }
  }

  render() {
    let { taskId, taskName } = this.props

    return (


      <div>
        {taskName}
        {this.renderTask(taskId)}

      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listening)
