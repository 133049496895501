import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import {
    Table,
    Badge,
    Card,
    Row,
    Drawer, Col,
    Tooltip,
    notification,
    Switch,
    Input, Button, Icon,
    Popconfirm
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import moment from 'moment'
// import Async from 'async'
import Each from 'async/each';

import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'

class AllTests extends Component {

    deleteTest = async data => {
        this.setState({loading: true})
        let x = await Request.deleteTest(data)

        this.setState({loading: false})

        this.table.current.reload()

        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }
    }
    apiRequest = (params, columns) => {
        return new Promise(async (resolve) => {
                let regExFilters = _.map(columns, x => x.key)
                params.sortField = 'publishedDate'
                params.sortOrder = 'descend'
                // params.sortField = 'testDate'
                // params.sortOrder = 'descend'
                let data = await Request.getAllTests({...params, regExFilters})
                this.setState({totalCount: data.total})
            resolve(data)
            /*Each(data.data, (item, done) => {
                    let data1 = Request.getBookedTestByStudents({id: item._id}).then((data1) => {
                        item.studentCount = data1.data.length
                        done()
                    })
                }, () => {
                    resolve(data)

             })*/

            }
        )
    }
    showDrawer = () => {
        this.setState({
            visible: true,
            loading: true
        })
    }
    bookedTest = async (testId) => {

        let data = await Request.getBookedTestByStudents({id: testId.testId})
        this.setState({
            bookedData: data.data,
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()

        let state = {visible: false, bookedData: []}

    }

    componentDidMount() {


    }

    render() {
        const {dispatch} = this.props
        let key = 1
        const columns = [
            {
                title: 'Test Number',
                dataIndex: 'testNo',
                key: 'testNo',
                searchTextName: 'testNo'
            },
            {
                title: 'Test Name',
                key: 'testName',
                width: 100,
                dataIndex: 'testName',
                searchTextName: 'testName'
            },

            {
                title: 'Test Duration(in seconds)',
                dataIndex: 'testDuration',
                key: 'testDuration',
                searchTextName: 'testDuration'
            },
          /*{
              title: 'Test Date',
              dataIndex: 'testDate',
              key: 'testDate',
              searchDateName: 'testDate',
              render: (val, record) => {
                  return (
                      <div>
                          {record.testDate
                              ? moment(record.testDate).format('DD-MMM-YYYY')
                              : ''}
                      </div>
                  )
              }
          },*/
          /*  {
                title: 'Published Date',
                dataIndex: 'publishedDate',
                key: 'publishedDate',
                searchDateName: 'publishedDate',
                render: (val, record) => {
                    return (
                        <div>
                            {record.publishedDate
                                ? moment(record.publishedDate).format('DD-MMM-YYYY')
                                : ''}
                        </div>
                    )
                }
            },*/
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                searchTextName: 'price'
            },

            {
                title: 'No of Questions',
                dataIndex: 'totalQuestions',
                key: 'totalQuestions',
                render: (val) => {
                    return <div>{val}</div>
                }

            },
            {
                title: 'No of Students',
                dataIndex: 'studentCount',
                key: 'studentCount',
                render: (val, r) => {
                    return <div>
                        <div style={{textAlign: 'center'}}>{val}</div>
                        <div style={{textAlign: 'center'}}><Button onClick={() => {
                            this.setState({
                                singleData: r
                            }, () => {
                                this.showDrawer()
                                this.bookedTest({testId: r._id})

                            })
                        }} loading={this.props.loading}>View Details</Button></div>
                    </div>
                }

            },
            {
                title: 'Created By ',
                key: 'createdByUser.name',
                dataIndex: 'createdByUser',
                width: 100,
                searchTextName: 'createdByUser.name',
                render: (text, record) => {
                    return (
                        <div>
                            <div>{text ? text.name : ''}</div>
                            <small>{text ? moment(text.time).format('lll') : ''}</small>
                        </div>
                    )
                }
            },
            {
                title: 'Last Modified By',
                width: 100,
                dataIndex: 'updatedByUser',
                key: 'updatedByUser.name',
                searchTextName: 'updatedByUser.name',
                render: (text, record) => {
                    return (
                        <div>
                            <div>{text ? text.name : ''}</div>
                            <small>{text ? moment(text.time).format('lll') : ''}</small>
                        </div>
                    )
                }
            },
            {
                key: 'actions',
                title: 'Actions', fixed: 'right',
                width: 150,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Test">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('tests.editTest', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete Test">
                            <Popconfirm
                                title="Are you sure delete this Test?"
                                onConfirm={() => {
                                    this.deleteTest({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    className={styles.btn} shape="circle"
                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                        <Tooltip title="Assign Test">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('students.allStudent', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="user"
                            />
                        </Tooltip>

                      <Tooltip title="Order Test">
                        <Button
                          shape="circle"
                          onClick={() => {
                            dispatch(
                              getUrlPushWrapper('tests.orderingTest', {
                                id: record._id
                              })
                            )
                          }}
                          icon="table"
                        />
                      </Tooltip>
                    </React.Fragment>
                }
            }
        ]
        const columns1 = [
            {
                title: 'sr.No',
                render: () => {
                    return <div>
                        {key++}

                    </div>
                }
            },
            {
                title: 'Student Name',
                dataIndex: 'studentId.name',
                key: 'studentId.name',
                searchTextName: 'testNo'
            }, {
                title: 'Email',
                dataIndex: 'studentId.email',
                key: 'studentId.email',
                searchTextName: 'email'
            },
            {
                title: 'Mobile',
                dataIndex: 'studentId.mobile',
                key: 'studentId.mobile',
                searchTextName: 'mobile'
            },
            {
                title: 'Address',
                dataIndex: 'studentId.address',
                key: 'studentId.address',
                searchTextName: 'address'
            },

          /*  {
                title: 'Test Date',
                dataIndex: 'testDate',
                key: 'testDate',
                render: (val) => {
                    return <div>{val && moment(val).format('YYYY-MM-DD')}</div>
                }
            }*/

        ]

        return (

            <PageHeaderWrapper
                title={`All Tests (${this.state && this.state.totalCount})`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={(params) => this.apiRequest(params, columns)}
                               pagination={{
                                   showSizeChanger: true,
                                   defaultPageSize: 100,
                                   pageSizeOptions: ['10', '20', '50', '100', '1000']

                               }}/>
                </Card>

                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: false,
                            bookedData: null
                        })
                    }}
                    visible={this.state && this.state.visible}
                    width={700}>
                    <div style={{paddingTop: 30}}>
                        <Row>
                            <Col>
                                <TableComp ref={this.table}
                                           columns={columns1}
                                           dataSource={this.state && this.state.bookedData}
                                           loading={this.state && !this.state.bookedData ? true : false}
                                />
                            </Col>

                        </Row>

                    </div>


                </Drawer>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTests)
