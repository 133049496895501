import React, {Component} from 'react'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    notification,
    Switch,
    Input, Button, Icon,
    Popconfirm, Form, Drawer
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'


@Form.create()
class ChangePassword extends Component {
    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                this.props.handleSubmit(valData)
                form.resetFields()

            }
        })

    }

    render() {
        const {submitting, dispatch} = this.props

        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        let inputTypes = {
            fields: [
                {
                    label: 'Current Password',
                    key: 'currentPassword',
                    type: 'password',
                    required: true
                },
                {
                    label: 'New Password',
                    key: 'password',
                    type: 'password',
                    required: true
                }
            ]
        }

        return (
            <div>
                <Form onSubmit={this.handleSubmit} style={{marginTop: 8}}>
                    <GetAllFormFields
                        inputSchema={inputTypes}
                        getFieldDecorator={getFieldDecorator}

                    />
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{marginBottom: 10, marginLeft: '33%'}}
                        loading={this.props.loading}>
                        SAVE
                    </Button>
                </Form>
            </div>
        )
    }
}

class AllUsers extends Component {

    deleteUser = async data => {
        this.setState({loading: true})
        await Request.deleteUser(data)

        this.setState({loading: false})

        this.table.current.reload()

        notification.success({
            message: 'Student deleted Successfully',
            duration: 20,
            key: `${data._id}-close`
        })
    }
    apiRequest = (params, columns) => {
        return new Promise(async (resolve) => {
            params.userType = ['Admin', 'admin']
            let data = await Request.getAllUser({
                ...params,
                regExFilters: ['name', 'city', 'country', 'address', 'tags', 'state', 'mobile', 'gender', 'dob', 'email', 'postcode']
            })
            this.setState({totalCount: data.total})

            resolve(data)
        })
    }
    showDrawer = () => {
        this.setState({
            visible: true
        })
    }

    constructor(props) {
        super(props)
        this.state = {}
        this.table = React.createRef()

    }

    componentDidMount() {
    }

    render() {
        const {dispatch} = this.props
        const {changePasswordRecord} = this.state


        const columns = [
            {
                title: 'Name',
                key: 'name',
                fixed: 'left',
                width: 100,
                dataIndex: 'name',
                searchTextName: 'name'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email'
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile'
            },
            {
                label: 'Valid Up To',
                key: 'deactivationDate',
                required: true,
                type: 'date',
                searchDateName: 'deactivationDate',
                render: (val, record) => {
                    return (
                        <div >
                            {record.deactivationDate
                                ? <div
                                    className={styles.pending}>{moment(record.deactivationDate).format('DD-MMM-YYYY')}</div>
                                : ''}
                        </div>
                    )
                }
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                searchTextName: 'address'
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                searchTextName: 'country'
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                searchTextName: 'state'

            },
            {
                title: 'Gender',
                dataIndex: 'gender',
                key: 'gender',
                searchTextName: 'gender'

            },
            {
                title: 'DOB ',
                key: 'dob',
                dataIndex: 'dob',
                searchDateName: 'dob',
                render: (val, record) => {
                    return (
                        <div>
                            {record.dob
                                ? moment(record.dob).format('DD-MMM-YYYY')
                                : ''}
                        </div>
                    )
                }
            }, {
                title: 'City',
                key: 'city',
                dataIndex: 'city',
                searchTextName: 'city'
            },
            {
                title: 'Post Code',
                key: 'postcode',
                dataIndex: 'postcode',
                searchTextName: 'postcode'
            },
            {
                title: 'Tags',
                width: 100,
                dataIndex: 'tags',
                key: 'tags',
                searchTextName: 'tags',
                render: cat => {

                    return cat && cat.join(',')
                }
            }, {
                key: 'actions',
                title: 'Actions', fixed: 'right',
                width: 80,
                render: (text, record) => {

                    return <React.Fragment>
                        <Tooltip title="Edit User">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('users.editUser', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete User">
                            <Popconfirm
                                title="Are you sure delete this User?"
                                onConfirm={() => {
                                    this.deleteUser({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    className={styles.btn} shape="circle"
                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                        <Tooltip title="Change Password">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    this.setState({
                                        changePasswordRecord: record
                                    }, () => {
                                        this.showDrawer()

                                    })

                                }}
                                icon="eye"
                            />
                        </Tooltip>


                    </React.Fragment>

                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={`All Admin Users (${this.state && this.state.totalCount})`}
            >


                <Card bordered={true}>
                    <TableComp ref={this.table} columns={columns}
                               extraProps={{
                                   scroll: {x: 1800}
                               }}
                               apiRequest={(params) => this.apiRequest(params, columns)}/>
                </Card>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                    visible={this.state.visible
                    }
                    width={600}>
                    <div>
                        <div style={{textAlign: 'center', padding: 20, fontSize: 18}}>
                            Change Password for
                            {changePasswordRecord && <b> {changePasswordRecord.name}</b>}
                        </div>

                        <ChangePassword
                            loading={this.props.loading}
                            handleSubmit={async values => {

                                dispatch(showLoader())

                                let x = await Request.changeUserPassword({
                                    _id: changePasswordRecord._id,
                                    newPassword: values.password,
                                    currentPassword: values.currentPassword
                                })

                                if (x.error) {
                                    notification.success({
                                        message: x.message
                                    })
                                } else {
                                    notification.success({
                                        message: x.message
                                    })
                                }

                                this.setState({})
                                dispatch(hideLoader())
                            }}
                        />

                    </div>


                </Drawer>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    search: router.location.search

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
