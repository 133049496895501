import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'

import SpeakingWriting from './speakingWriting'
import Reading from './Reading'
import Listening from './listening'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { Form, Button, Card, Icon, Tabs, Row, notification, Col } from 'antd'
import _ from 'lodash'
import Request from '../../request'

import { hideLoader, showLoader } from '../../modules/actions'
import { connect } from 'react-redux'
import moment from 'moment/moment'


@Form.create()
class AddQuestions extends PureComponent {

  next = e => {
    const { dispatch, form } = this.props
    //  e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {


      if (!err) {
        let values = _.clone(valData)
        // this.setState({val: valData})
        this.setState({ ...values, val: valData })
        //  dispatch(showLoader())
      }


    })
  }
  loadTaskDefinations = async (v) => {
    let taskArr = await Request.getTaskList()
    let inputTypes = this.state.inputTypes

    taskArr = _.filter(taskArr, x => x.section == v)

    inputTypes['fields']['1'].options = taskArr
    this.setState({ inputTypes, taskArr: taskArr })

  }
  setSample = async (obj, callback) => {
    const { dispatch, form } = this.props
    let { val } = this.state
    obj._id = this.state.id
    obj.section = val.sectionName
    obj.taskId = val.taskId
    obj.taskName = this.state.taskName
    if (obj.audio && obj.audio.length) {
      obj.audio = _.map(obj.audio, (item) => {
        return obj._id && item.response ? item.response : (!item.response && obj._id) ? item : item.response
      })
    }
    if (obj.questionImage && obj.questionImage.length) {
      obj.questionImage = _.map(obj.questionImage, (item) => {
        return obj._id && item.response ? item.response : (!item.response && obj._id) ? item : item.response
        // return obj._id ? item : item.response
      })
    }
    dispatch(showLoader())

    console.log(obj)
    let x = null
    if (this.state.id) {
      x = await Request.updateQuestion(obj)
    } else {
      x = await Request.addQuestion(obj)
    }

    dispatch(hideLoader())
    if (!x.error) {
      notification.success({
        message: this.state.id ? 'Question updated successfully' : 'Question added successfully'
      })
      return callback({ type: true, id: this.state.id })
      // this.props.form.setFieldsValue({})

    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
      return callback({ type: false })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      sectionName: null,
      val: '',
      taskId: null,
      taskName: null,
      taskArr: [],
      inputTypes: {
        fields: [
          {
            label: 'Choose Section',
            type: 'select',
            onChange: v => {

              this.loadTaskDefinations(v)

              this.props.form.setFieldsValue({
                sectionName: v,
                taskId: null
              })
              this.setState({
                taskId: null
              })
            },
            key: 'sectionName',
            options: ['Speaking & Writing', 'Reading', 'Listening'],
            required: true
          },
          {
            label: 'Choose Task', type: 'select',
            onChange: v => {
              {
                console.log(this.state.taskArr)
              }
              let findInd = _.find(this.state.taskArr, (vv) => {
                return vv.id == v
              })
              if (findInd) {
                this.setState({ taskName: findInd.task })
              }
              this.props.form.setFieldsValue({
                taskId: v
              })
              this.next()
            },
            key: 'taskId',
            keyAccessor: x => x.id,
            valueAccessor: x => x.task,
            options: [],
            required: true
          }
        ]
      }
    }
  }

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.search)

    let questionId = searchParams.get('id')
    if (questionId) {
      Request.getQuestionById({ id: questionId }).then(
        ({ data, error, message }) => {
          if (!error) {
            this.loadTaskDefinations(data.section)
            this.props.form.setFieldsValue({
              sectionName: data.section,
              taskId: data.taskId

            })

            this.setState({
              data: data, id: data._id, taskName: data.taskName
            })

            this.next()

          } else {
            notification.error({
              message: 'Error Getting Data',
              description: message
            })
          }
        }
      )
    }

  }

  RenderQuestionTemplate(sectioName, taskId, taskName = null) {

    if (sectioName && taskId) {
      switch (sectioName) {
        case 'Speaking & Writing':
          return <SpeakingWriting values={{ data: this.state.data }} setSample={this.setSample}
                                  taskName={taskName}
                                  taskId={taskId}/>
        case 'Reading':
          return <Reading setSample={this.setSample} values={{ data: this.state.data }} taskName={taskName}
                          taskId={taskId}/>
        case 'Listening':
          return <Listening setSample={this.setSample} values={{ data: this.state.data }} taskName={taskName}
                            taskId={taskId}/>
        default:
          return null
      }
    } else {
      return null
    }

  }

  render() {
    const { submitting } = this.props
    const { sectionName, taskId, taskName } = this.state
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const formProps = {
      getFieldDecorator,
      getFieldValue
    }

    return (
      <PageHeaderWrapper title={this.state.id ? 'Update Questions' : 'Add New Questions'}>
        <Form onSubmit={this.next}>

          <Card>
            <Row>
              <Col span={24}>
                <GetAllFormFields
                  inputSchema={this.state.inputTypes}
                  {...formProps}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}></Col>
              {/*<Col span={16}> <Button*/}
              {/*type="primary"*/}
              {/*htmlType="SAVE"*/}
              {/*style={{marginBottom: 10}}*/}
              {/*>*/}
              {/*NEXT*/}
              {/*</Button></Col>*/}
            </Row>
          </Card>


        </Form>

        <Card>
          {this.RenderQuestionTemplate(sectionName, taskId, taskName)}
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddQuestions)
