export default {}
export const pageTitle = 'PTE Academic'
export const subTitle = ''

//export const apiUrl = 'https://summitpteapi.scizers.com'
// export const apiUrl = 'http://localhost:8046'
 export const apiUrl = 'https://pteapi.scizers.com'



