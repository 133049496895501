import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard'
import Undercons from './containers/undercons'
import AllUsers from './containers/users/all'
import AddUsers from './containers/users/add'
import AddTests from './containers/Test/add'
import AllTests from './containers/Test/all'
import OrderingTest from './containers/Test/orderingTest'
import AllStudents from './containers/assignStudents/all'
import AllPayments from './containers/payments/all/index'

import AllQuestions from './containers/questions/all/index'
import AllBookedTests from './containers/bookedTest/all/index'
import AllStudentsList from './containers/students/all'
import AddQuestions from './containers/questions/addQuestions'
import CheckTest from './containers/bookedTest/checkNow'
import CheckTestPte from './containers/bookedTest/checkNowPte'


const menu = [
  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'icon': 'dashboard',
    'key': 'dashboard',
    'homepage': true,
    'component': Dashboard,
    'authority': [
      'Admin',
      'user'
    ]
  },
  {
    'path': '/users',
    'name': 'Users',
    'icon': 'user',
    'key': 'users',
    'component': Undercons,
    'authority': [
      'Admin',
      'user'
    ],
    'children': [
      {
        'path': '/users/add',
        'name': 'Add User/Student',
        'title': 'Add User',
        'component': AddUsers
      },
      {
        'path': '/users/all',
        'name': 'All Admin Users',
        'title': 'All Users',
        'component': AllUsers
      },
      {
        'path': '/user/edit-user',
        'name': 'Update User',
        'title': 'Update User',
        'key': 'editUser',
        dontShowOnMenu: true,
        'component': AddUsers
      }
    ]
  },
  {
    'path': '/question',
    'name': 'Question',
    'icon': 'user',
    'key': 'questions',
    'component': Undercons,
    'authority': [
      'Admin',
      'user'
    ],
    'children': [
      {
        'path': '/add',
        'name': 'Add Questions',
        'title': 'Add Questions',
        'component': AddQuestions
      },
      {
        'path': '/question/edit-question',
        'name': 'Question',
        'title': 'Question',
        'key': 'editQuestion',
        dontShowOnMenu: true,
        'component': AddQuestions
      },
      {
        'path': '/questions/all',
        'name': 'All Questions',
        'title': 'All Questions',
        'component': AllQuestions
      }
    ]
  },
  {
    'path': '/test',
    'name': 'Tests',
    'icon': 'form',
    'key': 'tests',


    'children': [
      {
        'path': '/tests/add',
        'name': 'Add Test',
        'title': 'Add Test',
        'component': AddTests
      },
      {
        'path': '/tests/all',
        'name': 'All Tests',
        'title': 'All Tests',
        'component': AllTests
      },
      {
        'path': '/test/edit-test',
        'name': 'Test ',
        'title': 'Test',
        'key': 'editTest',
        dontShowOnMenu: true,
        'component': AddTests
      },
      {
        'path': '/test/ordering-test',
        'name': 'Test ',
        'title': 'Test',
        'key': 'orderingTest',
        'dontShowOnMenu': true,
        'component': OrderingTest
      }
    ]
  },
  {
    'path': '/assignStudents',
    'name': 'AssignStudents',
    'icon': 'user',
    'key': 'students',
    'dontShowOnMenu': true,


    'children': [

      {
        'path': '/assignStudents/all',
        'name': 'All Assign Students',
        'title': 'All Assign Students',
        'key': 'allStudent',
        'component': AllStudents,
        'dontShowOnMenu': true


      }

    ]
  },
  {
    'path': '/BookedTests',
    'name': 'BookedTests',
    'icon': 'form',
    'key': 'bookedTest',

    'children': [

      {
        'path': '/BookedTests/all',
        'name': 'Booked Tests',
        'title': 'Booked Tests',
        'key': 'booked',
        'component': AllBookedTests
      },
      {
        'path': '/CheckTest/result',
        'name': 'Check Test',
        'title': 'check Test',
        dontShowOnMenu: true,
        'key': 'checkTest',
        'component': CheckTest
      },
      {
        'path': '/CheckTestPte/result',
        'name': 'Check Test',
        'title': 'check Test',
        dontShowOnMenu: true,
        'key': 'checkTestPte',
        'component': CheckTestPte
      }


    ]
  },
  {
    'path': '/studentList',
    'name': 'Student List',
    'icon': 'user',
    'key': 'studentList',
    'component': Undercons,
    'authority': [
      'Admin',
      'user'
    ],
    'children': [

      {
        'path': '/students/all',
        'name': 'All Students',
        'title': 'All Students',
        'component': AllStudentsList
      }
    ]
  },
  {
    'path': '/paymentList',
    'name': 'Payment List',
    'icon': 'user',
    'key': 'paymentList',
    'component': Undercons,
    'authority': [
      'Admin',
      'user'
    ],
    'children': [

      {
        'path': '/payment/all',
        'name': 'All Payments',
        'title': 'All Payments',
        'component': AllPayments
      }
    ]
  }


]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}
//</editor-fold>

export default menu

