import React from 'react'
import styles from './login.less'
import {Form, Icon, Input, Button, Checkbox, notification} from 'antd'

import Request from '../../request'
import {showLoader, hideLoader} from '../../modules/actions'

import {push} from 'connected-react-router'
import {connect} from 'react-redux'

class ForgotPasswordForm extends React.Component {

    handleSubmit = e => {
        const {dispatch} = this.props
        e.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                console.log(values)

                // dispatch(userActions(values))

                dispatch(showLoader())

                // let x = await Request.forgotPassword(values)
                // console.log(x)
                dispatch(hideLoader())
                //
                // if (!x.error) {
                //   //
                //   // localStorage.setItem('token', x.token)
                //   // localStorage.setItem('user', JSON.stringify(x.user))
                //   //
                //   // dispatch({
                //   //   type: 'SET_CURRENT_USER',
                //   //   user: x.user
                //   // })
                //
                //   setTimeout(() => {
                //     dispatch(push('/'))
                //   }, 300)
                //
                // } else {
                //   notification.error({
                //     message: 'Invalid Login',
                //     description: x.message
                //   })
                //
                // }
            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {loading} = this.props
        return (
            <Form onSubmit={this.handleSubmit} className={styles.main}>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [
                            {required: true, message: 'Please input your email address!'},
                            {type: 'email', message: 'Please input your valid email format'}
                        ]
                    })(
                        <Input
                            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Please input your email address"
                        />
                    )}
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        className={styles.button}>
                        Get Password !!
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPasswordForm)

const mapStateToProps = ({counter, global}) => ({
    count: counter.count,
    loading: global.buttonLoading
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedForgotPasswordForm)
