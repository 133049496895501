import React, {Component, PureComponent} from 'react'
import {Menu, Icon, notification} from 'antd'
import _ from 'lodash'
import S from 'string'
import {getUrlPushWrapper} from '../../routes'
import Request from '../../request'


class StudentHeader extends React.Component {
    state = {
        current: ''
    }

    handleClick = e => {
    }

    render() {
        let {ques} = this.props
        if (ques.taskId == 1) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Preparation Duration</td>
                        <td>{ques.preparationDuration}</td>
                    </tr>
                    <tr>
                        <td>Recording Duration</td>
                        <td>{ques.recordingDuration}</td>
                    </tr>
                    <tr>
                        <td>Play Tone</td>
                        <td>{ques && ques.playTone ? 'YES' : 'NO'}</td>
                    </tr>


                </table>

            </div>
        }

        if (ques.taskId == 2) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>question1</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques && ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Preparation Duration</td>
                        <td>{ques && ques.preparationDuration}</td>
                    </tr>
                    <tr>
                        <td>Recording Duration</td>
                        <td>{ques && ques.recordingDuration}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>

                </table>

            </div>
        }

        if (ques.taskId == 3) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Preparation Duration</td>
                        <td>{ques.preparationDuration}</td>
                    </tr>
                    <tr>
                        <td>Recording Duration</td>
                        <td>{ques.recordingDuration}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Play Tone</td>
                        <td>{ques && ques.playTone ? 'YES' : 'NO'}</td>
                    </tr>


                </table>

            </div>
        }

        if (ques.taskId == 4) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Preparation Duration</td>
                        <td>{ques.preparationDuration}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {_.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>
                    <tr>
                        <td>Recording Duration</td>
                        <td>{ques.recordingDuration}</td>
                    </tr>
                    <tr>
                        <td>Play Tone</td>
                        <td>{ques && ques.playTone ? 'YES' : 'NO'}</td>
                    </tr>


                </table>

            </div>
        }
        if (ques.taskId == 5) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Recording Duration</td>
                        <td>{ques.recordingDuration}</td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {_.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>


                </table>

            </div>
        }

        if (ques.taskId == 6) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Answer Duration</td>
                        <td>{ques.answerDuration}</td>
                    </tr>


                </table>

            </div>
        }
        if (ques.taskId == 7) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Answer Duration</td>
                        <td>{ques.answerDuration}</td>
                    </tr>


                </table>

            </div>
        }


        if (ques.taskId == 8) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{ques.body && S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>

                    <tr>
                        <td>Options</td>
                        <td>
                            {_.map(ques.fibOptions, (item) => {
                                return <table cellSpacing={10} cellPadding={10} border="1" style={{marginBottom: 10}}
                                              width="40%">
                                    <tr>
                                        <td colSpan={2}>list</td>
                                    </tr>
                                    <tr>
                                        <td>{_.map(item.options, (i) => {
                                            return <p>{i.value}</p>
                                        })}
                                        </td>
                                        <td>{_.map(item.options, (i) => {
                                            return <p>{i.correct ? 'YES' : 'NO'}</p>
                                        })}</td>
                                    </tr>


                                </table>
                            })}
                        </td>
                    </tr>

                </table>

            </div>
        }
        if (ques.taskId == 9) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{ques.body && S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>

                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Options</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>correct</td>
                                </tr>
                                {ques.mcqOptions && _.map(ques.mcqOptions, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.correct ? 'YES' : 'NO'}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }

        if (ques.taskId == 10) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Options</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>Order No</td>
                                </tr>
                                {ques.reorderParagraph && _.map(ques.reorderParagraph, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.orderNo}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }

        if (ques.taskId == 11) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Text Array</td>

                        <td>
                            <ul>
                                {ques.textArray && _.map(ques.textArray, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>

                    <tr>
                        <td>Options</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>Order No</td>
                                </tr>
                                {ques.reorderParagraph && _.map(ques.reorderParagraph, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.orderNo}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }

        if (ques.taskId == 12) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{ques.body && S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Options</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>correct</td>
                                </tr>
                                {ques.mcqOptions && _.map(ques.mcqOptions, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.correct ? 'YES' : 'NO'}}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }

        if (ques.taskId == 13) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>
                    <tr>
                        <td>Answer Duration</td>
                        <td>{ques.answerDuration}</td>
                    </tr>

                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>

                </table>

            </div>
        }

        if (ques.taskId == 14) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{ques.body && S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>
                    <tr>
                        <td>Option</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>correct</td>
                                </tr>
                                {ques.mcqOptions && _.map(ques.mcqOptions, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.correct ? 'YES' : 'NO'}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }
        if (ques.taskId == 15) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Text Array</td>

                        <td>
                            <ul>
                                {ques.textArray && _.map(ques.textArray, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}
                    </tr>
                    <tr>
                        <td>Options</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>Order No</td>
                                </tr>
                                {ques.reorderParagraph && _.map(ques.reorderParagraph, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.orderNo}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>


                </table>

            </div>
        }

        if (ques.taskId == 16) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>
                    <tr>
                        <td>Option</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>correct</td>
                                </tr>
                                {ques.mcqOptions && _.map(ques.mcqOptions, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.correct ? 'YES' : 'NO'}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }
        if (ques.taskId == 17) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>
                    <tr>
                        <td>Option</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>correct</td>
                                </tr>
                                {ques.mcqOptions && _.map(ques.mcqOptions, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.correct ? 'YES' : 'NO'}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }
        if (ques.taskId == 18) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>
                    <tr>
                        <td>Option</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>correct</td>
                                </tr>
                                {ques.mcqOptions && _.map(ques.mcqOptions, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.correct ? 'YES' : 'NO'}</td>
                                    </tr>

                                })}
                            </table>

                        </td>
                    </tr>

                </table>

            </div>
        }

        if (ques.taskId == 19) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>
                    <tr>
                        <td>Body</td>
                        <td>{ques.body && S(ques.body).stripTags().s}</td>
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>
                    <tr>
                        <td>Option</td>
                        <td>
                            <table cellPadding={10} cellSpacing={10} border="1" width="50%">
                                <tr>
                                    <td>value</td>
                                    <td>correct</td>
                                </tr>
                                {ques.mcqOptions && _.map(ques.mcqOptions, (item) => {
                                    return <tr>
                                        <td>{item.value}</td>
                                        <td>{item.correct ? 'YES' : 'NO'}</td>
                                    </tr>

                                })}
                            </table>

                        </td>


                    </tr>

                </table>

            </div>
        }
        if (ques.taskId == 20) {
            return <div>
                <table cellSpacing={10} cellPadding={10} border="1" width="99%">
                    <tr>
                        <td colSpan={3}><h3>Question Details</h3></td>
                    </tr>

                    <tr>
                        <td>Section</td>
                        <td>{ques && ques.section}</td>
                    </tr>
                    <tr>
                        <td>Task Name</td>
                        <td>{ques.taskName}</td>
                    </tr>


                    <tr>
                        <td>Tags</td>

                        <td>
                            <ul>
                                {ques.tags && _.map(ques.tags, (item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Audio</td>
                        {ques && _.map(ques.audio, (item) => {
                            return <td>{item.url}</td>
                        })}</tr>

                </table>

            </div>
        }

    }
}


export default StudentHeader
