import React, { Component } from 'react'
import { asyncContainer, Typeahead } from 'react-bootstrap-typeahead'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/index'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Popconfirm, Drawer,
  Row,
  Col,
  Switch,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import S from 'string'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
// import styles from './styles.less'
import { connect } from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import QuestionDetails from '../../../components/questions/questionDetails'

import { getUrlPushWrapper } from '../../../routes'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import styles from './styles.less'
const AsyncTypeahead = asyncContainer(Typeahead)

class AllPayments extends Component {


  apiRequest = (params, columns) => {
    return new Promise(async (resolve) => {
      if (this.state.selectedUser) {
        params['studentId'] = [this.state.selectedUser]
      }
      let data = await Request.getAllPayments({
        ...params, regExFilters: ['msg', 'status']
      })
      this.setState({ totalCount: data.total })
      resolve(data)
    })
  }
  selectedUser = (data) => {
    if (data && data.length) {
      this.setState({
        selectedUser: data[0]._id
      })
    } else {
      this.setState({
        selectedUser: null
      })
    }
    setTimeout(() => {
      this.table.current.reload()
    }, 500)
  }
  getStudentList = async (query) => {
    let students = await
      Request.studentList({ limit: 100, name: query })
    let studentArray = []

    this.setState({ studentArr: students })
  }
  showDrawer1 = () => {
    this.setState({
      visible1: true
    })
  }
  bookedDetails = async data1 => {
    this.setState({ loading: true })
    console.log(data1)
    let { data } = await Request.getBookedTest(data1)
    console.log(data)
    this.setState({ bookedTest: data })
    this.setState({ loading: false })
  }

  constructor(props) {
    super(props)
    this.state = {
      modeArr: ['Card', 'Cash'],
      studentArr: []
    }
    this.table = React.createRef()

  }

  componentDidMount() {
  }

  render() {
    const { dispatch } = this.props
    const { modeArr, bookedTest, singleData, studentArr } = this.state
    const columns = [
      {
        title: 'status',
        key: 'status',
        dataIndex: 'status',
        searchTextName: 'status'

      },
      {
        title: 'totalAmount',
        dataIndex: 'totalAmount',
        key: 'totalAmount'
      },
      {
        title: 'Message',
        key: 'msg',
        dataIndex: 'msg',
        searchTextName: 'msg'
      },
      {
        title: 'Mode',
        dataIndex: 'mode',
        key: 'mode',
        filters: modeArr && modeArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{text}</div>

          )
        }

      },
      {
        title: 'Date',
        width: 150,
        key: 'time',
        dataIndex: 'time',
        searchDateName: 'time',

        render: (text, record) => {
          return (
            <div>
              {text ? moment(text.time).format('YYYY-MM-DD') : ''}
            </div>
          )
        }
      },
      {
        tabletitle: 'Test Details',
        dataIndex: 'testIds',
        key: 'testIds',
        render: (val, record) => {
          return <div>{_.map(record.testIds, (item) => {
            return <div>{item.testName} , {item.testNo}</div>
          })}</div>
        }

      },
      /*{
        title: 'Student Name',
        dataIndex: 'studentId',
        key: 'studentId',
        filters: studentArr && studentArr.map(x => ({ value: x._id, text: x.name })),
        render: (val, record) => {
          return <div>{val.name} </div>
        }
      },*/

      {
        title: 'Student Name',
        dataIndex: 'studentId',
        key: 'studentId',
        render: (item) => {
          return <div>
            {item ? item.name : null}
          </div>

        }

      },
      {
        title: 'Mobile',
        dataIndex:
          'studentId.mobile',
        key:
          'studentId.mobile',
        render:
          (val, record) => {
            return <div>{val} </div>
          }
      }
      ,
      {
        title: 'Email',
        dataIndex:
          'studentId.email',
        key:
          'studentId.email',
        render:
          (val, record) => {
            return <div>{val} </div>
          }
      }
      ,

      {
        key: 'actions',
        title:
          'Actions', fixed:
          'right',
        width:
          150,
        render:
          (text, record) => {

            return <React.Fragment>


              <Tooltip title="View Booked Test">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  onClick={() => {
                    this.setState({
                      singleData: record.studentId._id
                    }, () => {
                      this.showDrawer1()
                      this.bookedDetails({ studentId: record.studentId._id })

                    })
                  }}
                  icon="table"
                />
              </Tooltip>

            </React.Fragment>

          }
      }
    ]
    const columns2 = [
      {
        title: 'TestName',
        dataIndex: 'testName',
        key: 'testName'
      },
      {
        title: 'TestDuration',
        dataIndex: 'testDuration',
        key: 'testDuration'
      },
      {
        title: 'TestDate',
        dataIndex: 'testDate',
        key: 'testDate',
        render: (val) => {
          return <div>{val && moment(val).format('YYYY-MM-DD')}</div>
        }
      }


    ]

    return (
      <PageHeaderWrapper
        title={`All Payments (${this.state && this.state.totalCount})`}>


        <Card bordered={true}>
          <Row>
            <Col span={8}>
              <span>Filter By Student</span>
              <AsyncTypeahead
                className={styles.typeaheadDrop}
                isLoading={this.state.isLoading}
                labelKey="name"
                onSearch={query => {
                  this.getStudentList(query)
                }}
                options={this.state.studentArr}
                onChange={this.selectedUser}
              />
            </Col>
          </Row>


          <TableComp ref={this.table} columns={columns}
                     scroll={{ x: 1400 }}
                     apiRequest={(params) => this.apiRequest(params, columns)}/>

        </Card>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible1: false
            })
          }}
          visible={this.state.visible1}
          width={600}>


          {bookedTest && <div>
            <Row>
              <Col>
                <TableComp ref={this.table}
                           columns={columns2}
                           dataSource={this.state.bookedTest}/>
              </Col>

            </Row>
          </div>}


        </Drawer>


      </PageHeaderWrapper>)

  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllPayments)
