import React, {PureComponent} from 'react'

import {notification, Row, Col, Form, Button, Input, Card, Checkbox, Table, Tooltip} from 'antd'
import Request from '../../request'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import PageHeaderWrapper from './addQuestions'
import _ from 'lodash'
import {API_URL} from '../../request'

import {connect} from 'react-redux'

@Form.create()

class Reading extends React.Component {

    state = {
        text: '',
        checked: false,
        checkedOption: [],
        tempText: [],
        tempCorrect: [],
        textData: [],
        option: '',
        allTextArea: [],
        inputText: '',
        data: '',
        allOptions: [],
        MultipleChoiceChooseMultipleAnswerOptions: {
            fields: [
                {
                  label: 'Question Body', type: 'editor', key: 'body', required: true, customDirectives: {
                        initialValue: ''
                    }
                },
                {
                  label: 'Question', type: 'editor', key: 'mcqBody', required: true, customDirectives: {
                        initialValue: ''
                    }
                },
                {
                    key: 'tags', type: 'select', mode: 'tags',
                    options: []
                    , onChange: (dd) => {
                        this.props.form.setFieldsValue({
                            tags: dd
                        })
                    }
                }
            ]

        },
        multipleChoiceSingleAnswer: {
            tabTitle: 'General Info',
            fields: [
                {
                  label: 'Question Body', type: 'editor', key: 'body', required: true, customDirectives: {
                        initialValue: ''
                    }
                },
                {
                  label: 'Question', type: 'editor', key: 'mcqBody', required: true, customDirectives: {
                        initialValue: ''
                    }
                },
                {
                    key: 'tags', type: 'select', mode: 'tags',
                    options: []
                    , onChange: (dd) => {
                        this.props.form.setFieldsValue({
                            tags: dd
                        })
                    }
                }
            ]
        },
        reorderParagraphs: {
            tabTitle: 'General Info',
            fields: [{
                key: 'tags', type: 'select', mode: 'tags',
                options: []
                , onChange: (dd) => {
                    this.props.form.setFieldsValue({
                        tags: dd
                    })
                }
            }]
        },
        fillInTheBlanks: {
            tabTitle: 'General Info',
            fields: [{
                key: 'tags', type: 'select', mode: 'tags',
                options: []
                , onChange: (dd) => {
                    this.props.form.setFieldsValue({
                        tags: dd
                    })
                }
            }]
        },
        readingWritingFib: {
            tabTitle: 'General Info',
            fields: [{
                key: 'tags', type: 'select', mode: 'tags',
                options: []
                , onChange: (dd) => {
                    this.props.form.setFieldsValue({
                        tags: dd
                    })
                }
            }]
        },
        allAnswers: [],
        fibOptions: []


    }
    handleClick = e => {
    }
    sendBack = async level => {
        let {rollBackLevel} = this.props
        if (level) {
            rollBackLevel = level
        }

        let x = await Request.changeDesk({
            _id: this.props.data._id,
            atLevel: rollBackLevel
        })

        if (x.error) {
        } else {
            if (!level) {
                this.props.done()

                notification.success({
                    message: `Student Moved To ${rollBackLevel}`
                })

            } else {
                let x = await Request.changeDesk({
                    _id: this.props.data._id,
                    atLevel: 'Dead Inquiry'
                })
                this.props.done()
                notification.success({
                    message: 'Student Inquiry Dead'
                })

            }
        }
    }
    handle1 = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                values.textArray = this.state.allTextArea
                values.fibOptions = this.state.fibOptions
                values.mcqOptions = this.state.allAnswers
                values.textArray = this.state.allTextArea
                values.reorderParagraph = this.state.allOptions
                this.props.setSample(values, (resp) => {
                    if (resp.type && !resp.id) {
                        form.resetFields()

                    }
                })
                form.resetFields()
                this.setState({allTextArea: [], fibOptions: [], allAnswers: [], allOptions: []})
            }
        })
    }
    renderTask = (taskId) => {

        const {form: {getFieldDecorator, getFieldValue}} = this.props
        const formProps = {getFieldDecorator, getFieldValue}
        const columns1 = [
            {
                title: 'Option',
                key: 'value',
                dataIndex: 'value',

                searchTextName: '',
                filterRegex: true
            },
            {
                title: 'Correct',
                key: 'correct',
                dataIndex: 'correct',
                render: (x) => {
                    return <span>{x ? 'YES' : 'NO'}</span>
                }
            },

            {
                title: 'Action',
                key: 'operation',
                icon: 'trash',

                render: (val, record) => (
                    <React.Fragment>
                        <Tooltip title="Delete">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    let tt = this.state.allAnswers
                                    tt = _.reject(tt, val)
                                    this.setState({allAnswers: tt})
                                }}
                                icon="delete"
                                size={'small'}
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ]

        const columns2 = [
            {
                title: 'Option',
                key: 'value',
                dataIndex: 'value',

                searchTextName: '',
                filterRegex: true
            },
            {
                title: 'Order',
                key: 'orderNo',
                dataIndex: 'orderNo'
            },

            {
                title: 'Action',
                key: 'operation',
                icon: 'trash',

                render: (val, record) => (
                    <React.Fragment>
                        <Tooltip title="Delete">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    let tt = this.state.allOptions
                                    tt = _.reject(tt, val)
                                    this.setState({allOptions: tt})
                                }}
                                icon="delete"
                                size={'small'}
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ]

        const columns3 = [
            {
                title: 'Text',
                key: 'text',
                dataIndex: 'text',
                searchTextName: '',
                filterRegex: true
            },


            {
                title: 'Action',
                key: 'operation',
                icon: 'trash',

                render: (val, record) => (

                    <React.Fragment>
                        <Tooltip title="Delete">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    {
                                        console.log(this.state.allTextArea)
                                    }
                                    let tt = this.state.allTextArea
                                    tt = _.reject(tt, val)
                                    this.setState({allTextArea: tt})
                                }}
                                icon="delete"
                                size={'small'}
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ]

        const columns4 = [
            {
                title: 'Options',
                key: 'value',
                dataIndex: 'value',
                searchTextName: '',
                filterRegex: true
            },
            {
                title: 'Correct',
                key: 'correct',
                dataIndex: 'correct',
                render: (x) => {
                    return <span>{x ? 'YES' : 'NO'}</span>
                }
            },


            {
                title: 'Action',
                key: 'operation',
                icon: 'trash',

                render: (val, record) => (
                    <React.Fragment>
                        <Tooltip title="Delete">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    let tt = this.state.allOptions
                                    tt = _.reject(tt, val)
                                    this.setState({allOptions: tt})
                                }}
                                icon="delete"
                                size={'small'}
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ]

        const columns5 = [
            {
                title: 'Options',
                key: 'value',
                dataIndex: 'value',
                searchTextName: '',
                filterRegex: true
            },
            {
                title: 'Correct',
                key: 'correct',
                dataIndex: 'correct',
                render: (x) => {
                    return <span>{x ? 'YES' : 'NO'}</span>
                }
            },


            {
                title: 'Action',
                key: 'operation',
                icon: 'trash',

                render: (val, record) => (
                    <React.Fragment>
                        <Tooltip title="Delete">
                            <Button
                                shape="circle"
                                onClick={() => {
                                    console.log(record)
                                    let tt = this.state.fibOptions
                                    _.each(tt, (cc) => {

                                        cc.options = _.reject(cc.options, x => x == record)
                                    })

                                    this.setState({fibOptions: tt})
                                }}
                                icon="delete"
                                size={'small'}
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ]

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 16}
            }
        }

        const {
            MultipleChoiceChooseMultipleAnswerOptions,
            multipleChoiceSingleAnswer,
            reorderParagraphs,
            fillInTheBlanks,
            allAnswers,
            readingWritingFib,
            allTextArea,
            checkedOption,
            inputText,
            allOptions,
            textData,
            fibOptions,
            data
        } = this.state

        switch (taskId) {
            case 8:
                return <div>
                    <Form {...formItemLayout} onSubmit={this.handle1}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={readingWritingFib}
                            {...formProps}
                        />

                        <div>
                            <Card bordered={true}>
                                <Row>
                                    <Col span={16}>

                                        <Form.Item label={'Add Text'}
                                        >
                                            <Input
                                                value={this.state.text}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({text: py})
                                                }}
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col span={8}> <Form.Item>
                                        <Button
                                            style={{marginBottom: 10, marginTop: 5, marginLeft: 46}}
                                            size={'small'}
                                            onClick={() => {
                                                let {text, allTextArea} = this.state
                                                let n = [...allTextArea]
                                                n.push({text: text})
                                                this.setState({
                                                    allTextArea: n
                                                })
                                                this.setState({
                                                    text: null
                                                })
                                            }}>
                                            ADD
                                        </Button>
                                    </Form.Item>
                                    </Col>


                                </Row>
                                <Row>
                                    <Table
                                        size={'small'}
                                        bordered={true}
                                        columns={columns3}
                                        dataSource={allTextArea}
                                    />
                                </Row>
                            </Card>
                            <Card bordered={true}>
                                <Row><Col>
                                    <Form.Item label={'Enter no of options'}
                                    >
                                        <Input
                                            value={this.state.data}
                                            style={{marginRight: 20}}
                                            onChange={py => {
                                                py = py.target.value
                                                let ss = []
                                                _.times(py, () => {
                                                    ss.push({
                                                        options: []
                                                    })
                                                })

                                                this.setState({
                                                    fibOptions: ss,
                                                    data: py
                                                })
                                            }}
                                        />
                                    </Form.Item></Col></Row>

                                {fibOptions.map((item, index) => {

                                    return <React.Fragment key={index}>
                                        <Row>
                                            <Col span={15}>
                                                <Form.Item label={'Add Option'}>
                                                    <Input
                                                        value={this.state.tempText[index]}
                                                        style={{marginRight: 20}}
                                                        onChange={py => {
                                                            py = py.target.value

                                                            let x = this.state.tempText
                                                            x[index] = py

                                                            this.setState({tempText: x})
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={4}> <Form.Item label={'Check'}>
                                                <Checkbox
                                                    checked={this.state.tempCorrect[index]}
                                                    onChange={y => {
                                                        let x = this.state.tempCorrect
                                                        x[index] = y.target.checked

                                                        this.setState({tempCorrect: x})
                                                    }}>
                                                </Checkbox>
                                            </Form.Item>
                                            </Col>


                                            <Col span={4}>
                                                <Form.Item>
                                                    <Button
                                                        htmyType={'button'}
                                                        size={'small'}
                                                        onClick={() => {
                                                            let {tempText, tempCorrect} = this.state
                                                            let n = [...fibOptions]
                                                            console.log(n)

                                                            n[index].options.push({
                                                                value: tempText[index],
                                                                correct: tempCorrect[index]
                                                            })

                                                            this.setState({
                                                                fibOptions: n,
                                                                tempText: [],
                                                                tempCorrect: []
                                                            })
                                                        }}>
                                                        ADD
                                                    </Button>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                        <Row> <Table
                                            size={'small'}
                                            bordered={true}
                                            columns={columns5}
                                            dataSource={item.options}
                                        /></Row>
                                    </React.Fragment>
                                })}

                            </Card>

                        </div>

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10, marginTop: 15}}
                                htmlType="SAVE"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 9:
                return <div>
                    <Form {...formItemLayout} onSubmit={this.handle1}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={multipleChoiceSingleAnswer}
                            {...formProps}
                        />
                        <div>
                            <Card bordered={true}>
                                <Row>
                                    <Col span={16}>

                                        <Form.Item label={'Answer'}
                                        >
                                            <Input
                                                value={this.state.text}
                                                style={{marginRight: 20}}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({text: py})
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label={'Check'}>
                                            <Checkbox
                                                checked={this.state.checked}
                                                loading={true}
                                                onChange={y => {
                                                    this.setState({checked: y.target.checked})
                                                }}>
                                            </Checkbox>
                                        </Form.Item>

                                    </Col>
                                    <Col span={4}>

                                        <Form.Item>
                                            <Button
                                                style={{marginBottom: 10, marginTop: 10}}
                                                size={'small'}
                                                onClick={() => {
                                                    let {text, allAnswers, checked} = this.state
                                                    let n = [...allAnswers]
                                                    n.push({value: text, correct: checked})
                                                    this.setState({
                                                        allAnswers: n
                                                    })
                                                    this.setState({
                                                        text: null,
                                                        checked: false
                                                    })
                                                }}>
                                                ADD
                                            </Button>
                                        </Form.Item>


                                    </Col>
                                </Row>
                                <Row>
                                    <Table
                                        size={'small'}
                                        bordered={true}
                                        columns={columns1}
                                        dataSource={allAnswers}
                                    />

                                </Row>
                            </Card>
                        </div>

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10, marginTop: 15}}
                                htmlType="SAVE"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>

            case 10:
                return <div>
                    <Form {...formItemLayout} onSubmit={this.handle1}>

                        <GetAllFormFields
                            inputSchema={MultipleChoiceChooseMultipleAnswerOptions}
                            {...formProps}
                        />

                        <div>
                            <Card bordered={true}>
                                <Row>
                                    <Col span={15}>

                                        <Form.Item label={'Answer'}
                                        >
                                            <Input
                                                value={this.state.text}
                                                style={{marginRight: 20}}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({text: py})
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={4}>
                                        <Form.Item label={'Check'}>
                                            <Checkbox
                                                checked={this.state.checked}
                                                loading={true}
                                                onChange={y => {
                                                    this.setState({checked: y.target.checked})
                                                }}>
                                            </Checkbox>
                                        </Form.Item>

                                    </Col>
                                    <Col span={4}>

                                        <Form.Item>
                                            <Button
                                                style={{marginBottom: 10, marginTop: 10}}
                                                size={'small'}
                                                onClick={() => {
                                                    let {text, allAnswers, checked} = this.state
                                                    let n = [...allAnswers]
                                                    n.push({value: text, correct: checked})
                                                    this.setState({
                                                        allAnswers: n
                                                    })
                                                    this.setState({
                                                        text: null,
                                                        checked: false
                                                    })
                                                }}>
                                                ADD
                                            </Button>
                                        </Form.Item>


                                    </Col>
                                </Row>
                                <Row>
                                    <Table
                                        size={'small'}
                                        bordered={true}
                                        columns={columns1}
                                        dataSource={allAnswers}
                                    />
                                </Row>
                            </Card>
                        </div>

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10, marginTop: 15}}
                                htmlType="SAVE"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 11:
                return <div>
                    <Form {...formItemLayout} onSubmit={this.handle1}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={reorderParagraphs}
                            {...formProps}
                        />
                        <div>
                            <Card bordered={true}>
                                <Row>
                                    <Col span={16}>

                                        <Form.Item label={'Enter Option'}
                                        >
                                            <Input
                                                value={this.state.text}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({text: py})
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label={'Order'}>
                                            <Input
                                                value={this.state.option}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({option: py})
                                                }}
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col span={4}>

                                        <Form.Item>
                                            <Button
                                                style={{marginLeft: 10}}
                                                size={'small'}
                                                onClick={() => {
                                                    let {text, allOptions, option} = this.state
                                                    let n = [...allOptions]
                                                    n.push({value: text, orderNo: option})
                                                    this.setState({
                                                        allOptions: n
                                                    })
                                                    this.setState({
                                                        text: null,
                                                        option: null
                                                    })
                                                }}>
                                                ADD
                                            </Button>
                                        </Form.Item>


                                    </Col>
                                </Row>
                                <Row>
                                    <Table
                                        size={'small'}
                                        bordered={true}
                                        columns={columns2}
                                        dataSource={allOptions}
                                    />

                                </Row>
                            </Card>
                        </div>

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10, marginTop: 15}}
                                htmlType="SAVE"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>
            case 12:
                return <div>
                    <Form {...formItemLayout} onSubmit={this.handle1}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={fillInTheBlanks}
                            {...formProps}
                        />
                        <div>
                            <Card bordered={true}>
                                <Row>
                                    <Col span={16}>
                                        <Form.Item label={'Add Text'}
                                        >
                                            <Input
                                                value={this.state.text}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({text: py})
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}> <Form.Item>
                                        <Button
                                            style={{marginBottom: 10, marginTop: 5, marginLeft: 46}}
                                            size={'small'}
                                            onClick={() => {
                                                let {text, allTextArea} = this.state
                                                let n = [...allTextArea]
                                                n.push({text: text})
                                                this.setState({
                                                    allTextArea: n
                                                })
                                                this.setState({
                                                    text: null
                                                })
                                            }}>
                                            ADD
                                        </Button>
                                    </Form.Item>
                                    </Col>

                                </Row>
                                <Row>
                                    <Table
                                        size={'small'}
                                        bordered={true}
                                        columns={columns3}
                                        dataSource={allTextArea}
                                    />
                                </Row>
                            </Card>

                            <Card bordered={true}>
                                <Row>
                                    <Col span={16}>
                                        <Form.Item label={'Enter Option'}
                                        >
                                            <Input
                                                value={this.state.inputText}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({inputText: py})
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label={'Order'}>
                                            <Input
                                                value={this.state.option}
                                                onChange={py => {
                                                    py = py.target.value
                                                    this.setState({option: py})
                                                }}
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col span={4}>

                                        <Form.Item>
                                            <Button
                                                style={{marginLeft: 10}}
                                                size={'small'}
                                                onClick={() => {
                                                    let {inputText, allOptions, option} = this.state
                                                    let n = [...allOptions]
                                                    n.push({value: inputText, orderNo: option})
                                                    this.setState({
                                                        allOptions: n
                                                    })
                                                    this.setState({
                                                        inputText: null,
                                                        option: null
                                                    })
                                                }}>
                                                ADD
                                            </Button>
                                        </Form.Item>


                                    </Col>
                                </Row>
                                <Row>
                                    <Table
                                        size={'small'}
                                        bordered={true}
                                        columns={columns2}
                                        dataSource={allOptions}
                                    />

                                </Row>
                            </Card>
                        </div>

                        <Row>
                            <Col span={8}></Col>
                            <Col span={16}> <Button
                                type="primary"
                                style={{marginBottom: 10, marginTop: 15}}
                                htmlType="SAVE"
                                loading={this.props.loading}>
                                SAVE
                            </Button></Col>
                        </Row>

                    </Form>
                </div>


            default:
                return null

        }
    }

    componentDidMount() {
        if (this.props.values) {
            let {data} = this.props.values
            if (data) {
                let text = []
                _.each(data.textArray, (item) => {
                    let abc = {}
                    abc.text = item
                    text.push(abc)
                })
                this.props.form.setFieldsValue({
                    // body: data && data.body,
                    // preparationDuration: data && data.preparationDuration,
                    answerDuration: data && data.answerDuration,
                    tags: data && data.tags,
                    audio: data && data.audio
                })
                this.setState({
                    allTextArea: text,
                    fibOptions: data.fibOptions,
                    allAnswers: data.mcqOptions,
                    allOptions: data.reorderParagraph

                })

            }


        }
    }

    render() {
        let {taskId, taskName} = this.props

        return (


            <div>
                {taskName}
                {this.renderTask(taskId)}

            </div>

        )
    }
}


const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Reading)
