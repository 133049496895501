import React, { Component } from 'react'
// import PageHeaderWrapper from '../../../components/PageHeaderWrapper/index'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/index'
import ReactAudioPlayer from 'react-audio-player'
import { apiUrl } from '../../settings'
import { goBack } from 'connected-react-router'
import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Popconfirm, Drawer,
  Tag,
  InputNumber,
  Row,
  Col,
  Switch,
  Input, Button, Icon, Checkbox
} from 'antd'
import Request from '../../request'
import S from 'string'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './style.less'
import { connect } from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'

import { getUrlPushWrapper } from '../../routes'
import { hideLoader, showLoader } from '../../modules/actions'
import { Form } from 'antd/lib/form'

let nameObj = {
  grammar: 'Grammar',
  oralFluency: 'Oral Fluency',
  pronunciation: 'Pronunciation',
  spelling: 'Spelling',
  writtenDiscourse: 'Written Discourse',
  vocabulary: 'Vocabulary',
  totalMarks: 'Total Marks',
  marks: 'Obtained Marks',
  speaking: 'Speaking',
  writing: 'Writing',
  reading: 'Reading',
  listening: 'Listening'
}

let totalMarks = {
  'grammar': 0,
  'oralFluency': 0,
  'pronunciation': 0,
  'spelling': 0,
  'writtenDiscourse': 0,
  'vocabulary': 0,
  'speaking': 0,
  'writing': 0,
  'reading': 0,
  'listening': 0
}
const { TextArea } = Input

class CollapseViewBox extends Component {

  state = {
    cp: true
  }

  render() {
    const { cp } = this.state
    return <React.Fragment>
      <div className={cp ? styles.questioncollapse : styles.questioncollapsenot}>{this.props.children}</div>
      <a style={{ color: 'red' }} onClick={() => {
        this.setState({
          cp: !cp
        })
      }}>
        {cp ? <p>Read More + </p> : <p>Read Less - </p>}
      </a>

    </React.Fragment>
  }
}

class ExtraMarksClass extends Component {

  state = {
    children: ''
  }

  componentDidMount() {
    const { children } = this.props
    let cloneChild = _.clone(children)
    if ([1, 3, 4].includes(cloneChild.taskId)) {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'oralFluency', value: null,extraName: 'Oral(5)' },
          { name: 'pronunciation', value: null,extraName: 'Pron.(5)' },
          { name: 'vocabulary', value: null, extraName: 'VB.(5)' }
        ]
      }

    }
    else if ([2].includes(cloneChild.taskId)) {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'oralFluency', value: null,extraName: 'Oral(5)' },
          { name: 'pronunciation', value: null,extraName: 'Pron.(5)' },
          { name: 'vocabulary', value: null, extraName: 'VB.(3)' }
        ]
      }

    }
    else if ([5].includes(cloneChild.taskId)) {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'oralFluency', value: null,extraName: 'Oral(0.3)' },
          { name: 'pronunciation', value: null,extraName: 'Pron.(0.3)' },
          { name: 'vocabulary', value: null, extraName: 'VB.(0.4)' }
        ]
      }

    }
    else if ([6].includes(cloneChild.taskId)) {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'grammar', value: null , extraName: 'Gram.(2)' },
          { name: 'spelling', value: null , extraName: 'Spell.(2)' },
          { name: 'vocabulary', value: null, extraName: 'VB.(1)' },
          { name: 'writtenDiscourse', value: null, extraName: 'W.D(2)' }
        ]
      }

    }
    else if ([7].includes(cloneChild.taskId)) {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'grammar', value: null , extraName: 'Gram.(4)' },
          { name: 'spelling', value: null , extraName: 'Spell.(3)' },
          { name: 'vocabulary', value: null, extraName: 'VB.(4)' },
          { name: 'writtenDiscourse', value: null, extraName: 'W.D(4)' }
        ]
      }

    }
    else if ([13].includes(cloneChild.taskId)) {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'grammar', value: null , extraName: 'Gram.(3)' },
          { name: 'spelling', value: null , extraName: 'Spell.(2)' },
          { name: 'vocabulary', value: null, extraName: 'VB.(2)' },
          { name: 'writtenDiscourse', value: null, extraName: 'W.D(3)' }
        ]
      }

    }
    else if ([20].includes(cloneChild.taskId)) {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'marks', value: null , extraName: 'Obtain  Marks' },
          { name: 'totalMarks', value: null, extraName: 'Total Marks' }
        ]
      }
    } else {
      if (!cloneChild.enableSkillMarks) {
        cloneChild.enableSkillMarks = [
          { name: 'marks', value: null , extraName: 'Obtain Marks' }
        ]
      }
    }
    this.setState({
      children: cloneChild
    })
  }

  setFieldValue(data, name) {
    let cloneChildren = _.clone(this.state.children)
    let findV = _.find(this.state.children.enableSkillMarks, (item) => {
      return item.name == name
    })
    if (findV) {
      findV.value = data.target.value ? parseFloat(data.target.value) : null
      this.setState({ children: cloneChildren })
      this.props.countMarksCallback(this.state.children.enableSkillMarks, this.state.children._id)

    }
  }

  render() {
    const { children } = this.state


    return <React.Fragment>
      {children.enableSkillMarks && children.enableSkillMarks.length && children.enableSkillMarks.map((item, index) => {
        return (
          <Row key={index} style={{ marginBottom: 5 }}>
            <Col span={15}>
              {item.extraName ? item.extraName : nameObj[item.name]}
            </Col>
            <Col span={9}>
              <Input onChange={(value) => {
                this.setFieldValue(value, item.name)
              }} value={item.value} className={styles.inputPadding}/>
            </Col>
          </Row>
        )
      })}

    </React.Fragment>
  }
}


class checkNowPte extends Component {


  renderAnswer = (val) => {

    if (val.reorderOptions && val.reorderOptions.length) {
      return <div>
        <ul>
          {val.reorderOptions && _.map(val.reorderOptions, (item) => {
            return <li>{item.value}</li>
          })}
        </ul>
      </div>
    }
    if (val.options && val.options.length) {

      return <div>
        {val.options && _.map(val.options, (item) => {
          return item.value
        })}

      </div>


    }
    if (val.text) {
      return <div>{val.text}</div>
    }


    if (val.fibOptions && val.fibOptions.length) {
      return <div>
        <ul>
          {val.fibOptions && _.map(val.fibOptions, (item) => {
            return <li>{item.value}</li>
          })}
        </ul>
      </div>
    }
    if (val.recording && val.recording.length) {
      return <div>
        <ReactAudioPlayer
          src={val.recording[0].url}
          autoPlay={false}
          controls
        />

      </div>
    }


  }
  renderQuestion = (record) => {

    if (record.taskId && record.taskId == 1) {

      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          return <div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>
        }
        return <CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>
      }

    }
    if (record.taskId && record.taskId == 2) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>
      }

    }
    if (record.taskId && record.taskId == 3) {
      if (record.questionImage && record.questionImage.length) {
        return <div>{record.questionImage && _.map(record.questionImage, (item) => {
          return <img src={item.url} style={{ width: 100 }}/>
        })}</div>
      }

    }
    if (record.taskId && record.taskId == 4) {
      let out = []
      if (record.questionImage && record.questionImage.length) {
        out.push(<div style={{ paddingBottom: 10 }}>{record.questionImage && _.map(record.questionImage, (item) => {
          return <img src={item.url} style={{ width: 100 }}/>
        })}</div>)
      }
      if (record.audio && record.audio.length) {
        out.push(<div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }

      return out

    }
    if (record.taskId && record.taskId == 5) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />
        </div>
      }
    }
    if (record.taskId && record.taskId == 6) {
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          return <div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>
        }
        return <CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>
      }

    }
    if (record.taskId && record.taskId == 7) {
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          return <div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>
        }
        return <CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>
      }
    }
    if (record.taskId && record.taskId == 8) {
      let out = []
      if (record.textArray && record.textArray.length) {
        out.push(<div>
          {record.textArray && _.map(record.textArray, (item, index) => {
            return <span>{item}
              {
                record.fibOptions[index] && record.fibOptions[index].options.map((fibVal, oo) => {
                  return <Tag key={oo}>{fibVal.value} {fibVal.correct &&
                  <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</Tag>
                })
              }</span>
          })}
        </div>)

      }

      return out
    }
    if (record.taskId && record.taskId == 9) {
      let out = []

      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }

      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div style={{ paddingTop: 10 }}>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }
      return out
    }
    if (record.taskId && record.taskId == 10) {
      let out = []
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div style={{ paddingTop: 10 }}>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }
      return out

    }
    if (record.taskId && record.taskId == 11) {
      if (record.reorderParagraph && record.reorderParagraph.length) {
        return <div>
          <ul>{record.reorderParagraph && _.map(record.reorderParagraph, (item) => {
            return <li>{item.value} <span className={styles.label}>{item.orderNo}</span></li>
          })}</ul>
        </div>
      }

    }

    if (record.taskId && record.taskId == 12) {
      let out = []

      if (record.textArray && record.textArray.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          {record.textArray && _.map(record.textArray, (item, index) => {
            return <span>{item} {index < record.textArray.length - 1 ? <Button style={{
              width: 60, height: 25, marginLeft: 1,
              marginRight: 8
            }}></Button> : null}</span>
          })}
        </div>)
      }
      if (record.reorderParagraph && record.reorderParagraph.length) {
        out.push(<div>
          {record.reorderParagraph && _.map(record.reorderParagraph, (item) => {
            return <Tag>{item.value} , {item.orderNo}</Tag>
          })}
        </div>)
      }

      return out
    }

    if (record.taskId && record.taskId == 13) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>
      }
    }
    if (record.taskId && record.taskId == 14) {
      let out = []
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10, paddingTop: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }

      return out

    }

    if (record.taskId && record.taskId == 15) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.textArray && record.textArray.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          {record.textArray && _.map(record.textArray, (item, index) => {
            return <span>{item} {index < record.textArray.length - 1 ? <Button style={{
              width: 60, height: 25, marginLeft: 1,
              marginRight: 8
            }}></Button> : null}</span>
          })}
        </div>)
      }
      if (record.reorderParagraph && record.reorderParagraph.length) {
        out.push(<div>
          {record.reorderParagraph && _.map(record.reorderParagraph, (item) => {
            return <Tag>{item.value} , {item.orderNo}</Tag>
          })}
        </div>)
      }

      return out

    }

    if (record.taskId && record.taskId == 16) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }

      return out
    }
    if (record.taskId && record.taskId == 17) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }


      return out
    }
    if (record.taskId && record.taskId == 18) {
      let out = []
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }


      return out
    }
    if (record.taskId && record.taskId == 19) {

      let out = []
      if (record.body) {
        let u = record.body
        if (u.length <= 200) {
          out.push(<div><b>{u ? S(u).stripTags().s : 'N/A'}</b></div>)
        }
        else {
          out.push(<CollapseViewBox><b>{u ? S(u).stripTags().s : 'N/A'}</b></CollapseViewBox>)

        }
      }
      if (record.audio && record.audio.length) {
        out.push(<div style={{ paddingBottom: 10, paddingTop: 10 }}>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>)
      }
      if (record.mcqOptions && record.mcqOptions.length) {
        out.push(<div>
          <ul>{record.mcqOptions && _.map(record.mcqOptions, (item) => {
            return <li>{item.value}{item.correct &&
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>}</li>
          })}</ul>
        </div>)
      }


      return out

    }
    if (record.taskId && record.taskId == 20) {
      if (record.audio && record.audio.length) {
        return <div>
          <ReactAudioPlayer
            src={record.audio[0].url}
            autoPlay={false}
            controls
          />

        </div>
      }
    }

  }
  countMarksCallback = (enableSkillMarks, id) => {
    let totalMarksNew = _.clone(totalMarks)
    let obtainMarks = {
      'grammar': 0,
      'oralFluency': 0,
      'pronunciation': 0,
      'spelling': 0,
      'writtenDiscourse': 0,
      'vocabulary': 0,
      'speaking': 0,
      'totalMarks': 0,
      'marks': 0,
      'writing': 0,
      'reading': 0,
      'listening': 0
    }
    let studentTestData = _.clone(this.state.studentTestData)
    let findObj = _.find(studentTestData, (item) => {
      return item._id == id
    })
    if (findObj) {
      findObj.enableSkillMarks = enableSkillMarks
    }
    _.each(studentTestData, (item) => {
      if (item.enableSkillMarks) {
        _.each(item.enableSkillMarks, (item1, key) => {
          if (item1.value) {
            obtainMarks[item1.name] = parseFloat(obtainMarks[item1.name]) + parseFloat(item1.value)

            if (item.taskId == 1) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.reading += item1.value / 2
            }
            if (item.taskId == 2) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 3) {
              obtainMarks.speaking += item1.value
            }
            if (item.taskId == 4) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 5) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 6) {
              obtainMarks.writing += item1.value / 2
              obtainMarks.reading += item1.value / 2
            }
            if (item.taskId == 7) {
              obtainMarks.writing += item1.value
            }
            if (item.taskId == 8) {
              obtainMarks.writing += item1.value / 2
              obtainMarks.reading += item1.value / 2
            }
            if ([9, 10, 11, 12].includes(item.taskId)) {
              obtainMarks.reading += item1.value
            }
            if (item.taskId == 13) {
              obtainMarks.writing += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if ([14, 17, 18].includes(item.taskId)) {
              obtainMarks.listening += item1.value
            }
            if ([16, 19].includes(item.taskId)) {
              obtainMarks.reading += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 20) {
              if (item1.name == 'Total Marks') {
                totalMarksNew.listening += item1.value
              } else {
                obtainMarks.writing += item1.value / 2
                obtainMarks.listening += item1.value / 2
              }
            }
          }
        })
      }
    })


    setTimeout(() => {
      this.calculateScore(obtainMarks, (resp) => {
        this.setState({ studentTestData, obtainMarks: obtainMarks, score: resp })
      })

    }, 500)
  }

  constructor(props) {
    super(props)
    this.state = {
      obtainedMarksResult: [],
      checked: false,
      tempCorrect: [],
      feedback: null,
      obtainMarks: {
        'grammar': 0,
        'oralFluency': 0,
        'pronunciation': 0,
        'spelling': 0,
        'writtenDiscourse': 0,
        'totalMarks': 0,
        'marks': 0,
        'vocabulary': 0,
        'speaking': 0,
        'writing': 0,
        'reading': 0,
        'listening': 0
      },
      score: {}
    }
    this.table = React.createRef()

  }

  async componentDidMount() {

    let searchParams = new URLSearchParams(this.props.search)
    let bookedTestId = searchParams.get('id')

    if (bookedTestId) {

      let studentTest = await Request.getStudentTestById({ id: bookedTestId })
      if (studentTest) {
        let { data, error, message } = studentTest
        if (!error) {
          this.setState({
            studentTestData: data.questions,
            testName: data.testName,
            testDate: data.testDate,
            testNo: data.testNo,
            id: bookedTestId,
            feedback: data.feedback
          })
          setTimeout(() => {
            this.calculateTotalMarks()
            this.setObtainMarks()

          }, 500)

        }


      }


    }
  }

  async updateMarks() {
    if (this.state.studentTestData && this.state.id) {

      let { studentTestData } = this.state
      let studentTestObj = []
      _.each(studentTestData, (item, key) => {
        studentTestObj.push({
          questionId: item.questionId,
          enableSkillMarks: item.enableSkillMarks
        })
      })
      this.setObtainMarks()
      let obj = {
        studentTestId: this.state.id,
        marksData: studentTestObj,
        totalMarks: totalMarks,
        score: this.state.score,
        obtainMarks: this.state.obtainMarks,
        testStatus: 'Checked',
        feedback: this.state.feedback
      }
      this.props.dispatch(showLoader())
      let x = await Request.addStudentTestMarksWithEnableSkills(obj)
      this.props.dispatch(hideLoader())
      if (!x.error) {
        notification.success({ message: x.message })
        window.open(`${apiUrl}/student/result/${this.state.id}`, '_blank')
        this.props.dispatch(goBack())
      }
      else {
        notification.error({ message: 'Error saving data' })
      }

    }
  }

  setObtainMarks() {
    let totalMarksNew = _.clone(totalMarks)
    let obtainMarks = {
      'grammar': 0,
      'oralFluency': 0,
      'pronunciation': 0,
      'spelling': 0,
      'writtenDiscourse': 0,
      'vocabulary': 0,
      'speaking': 0,
      'totalMarks': 0,
      'marks': 0,
      'writing': 0,
      'reading': 0,
      'listening': 0
    }
    let studentTestData = _.clone(this.state.studentTestData)
    _.each(studentTestData, (item) => {
      if (item.enableSkillMarks) {
        _.each(item.enableSkillMarks, (item1, key) => {
          if (item1.value) {
            obtainMarks[item1.name] = parseFloat(obtainMarks[item1.name]) + parseFloat(item1.value)

            if (item.taskId == 1) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.reading += item1.value / 2
            }
            if (item.taskId == 2) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 3) {
              obtainMarks.speaking += item1.value
            }
            if (item.taskId == 4) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 5) {
              obtainMarks.speaking += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 6) {
              obtainMarks.writing += item1.value / 2
              obtainMarks.reading += item1.value / 2
            }
            if (item.taskId == 7) {
              obtainMarks.writing += item1.value
            }
            if (item.taskId == 8) {
              obtainMarks.writing += item1.value / 2
              obtainMarks.reading += item1.value / 2
            }
            if ([9, 10, 11, 12].includes(item.taskId)) {
              obtainMarks.reading += item1.value
            }
            if (item.taskId == 13) {
              obtainMarks.writing += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if ([14, 17, 18].includes(item.taskId)) {
              obtainMarks.listening += item1.value
            }
            if ([16, 19].includes(item.taskId)) {
              obtainMarks.reading += item1.value / 2
              obtainMarks.listening += item1.value / 2
            }
            if (item.taskId == 20) {
              if (item1.name == 'Total Marks') {
                totalMarksNew.listening += item1.value
              } else {
                obtainMarks.writing += item1.value / 2
                obtainMarks.listening += item1.value / 2
              }
            }
          }
        })
      }
    })


    setTimeout(() => {
      this.calculateScore(obtainMarks, (resp) => {
        this.setState({ studentTestData, obtainMarks: obtainMarks, score: resp })
      })

    }, 500)
  }

  calculateScore(data, callback) {
    let score = {
      'grammar': 0,
      'oralFluency': 0,
      'pronunciation': 0,
      'spelling': 0,
      'writtenDiscourse': 0,
      'vocabulary': 0,
      'speaking': 0,
      'writing': 0,
      'reading': 0,
      'listening': 0
    }
    _.each(data, (item, key) => {
      let findTotal = totalMarks[key]
      if (score[key] !== undefined && item) {
        score[key] = Math.round((90 / findTotal) * item)
      }
    })
    return callback(score)
  }

  calculateTotalMarks() {
    let studentTestData = _.clone(this.state.studentTestData)
    let questionGrouping = _.groupBy(studentTestData, (item) => {
      return item.taskId
    })
    _.each(questionGrouping, (item, key) => {
      key = parseInt(key)
      if (key == 1) {
        let totalAmount = item.length * 15
        totalMarks['oralFluency'] += item.length * 5
        totalMarks['pronunciation'] += item.length * 5
        totalMarks['vocabulary'] += item.length * 5
        totalMarks.speaking = totalMarks.speaking + (totalAmount / 2)
        totalMarks.reading = totalMarks.reading + (totalAmount / 2)
      } else if (key == 4) {
        let totalAmount = item.length * 15
        totalMarks['oralFluency'] += item.length * 5
        totalMarks['pronunciation'] += item.length * 5
        totalMarks['vocabulary'] += item.length * 5
        totalMarks.speaking = totalMarks.speaking + (totalAmount / 2)
        totalMarks.listening = totalMarks.listening + (totalAmount / 2)
      } else if (key == 3) {
        totalMarks['oralFluency'] += item.length * 5
        totalMarks['pronunciation'] += item.length * 5
        totalMarks['vocabulary'] += item.length * 5
        let totalAmount = item.length * 15
        totalMarks.speaking = totalMarks.speaking + totalAmount
      } else if (key == 2) {
        totalMarks['oralFluency'] += item.length * 5
        totalMarks['pronunciation'] += item.length * 5
        totalMarks['vocabulary'] += item.length * 3
        let totalAmount = item.length * 13
        totalMarks.speaking = totalMarks.speaking + (totalAmount / 2)
        totalMarks.listening = totalMarks.listening + (totalAmount / 2)
      } else if (key == 5) {

        totalMarks['oralFluency'] += item.length * 0.3
        totalMarks['pronunciation'] += item.length * 0.3
        totalMarks['vocabulary'] += item.length * 0.4
        let totalAmount = item.length * 1
        totalMarks.speaking = totalMarks.speaking + (totalAmount / 2)
        totalMarks.listening = totalMarks.listening + (totalAmount / 2)
      } else if (key == 6) {
        totalMarks['grammar'] += item.length * 2
        totalMarks['spelling'] += item.length * 2
        totalMarks['vocabulary'] += item.length * 1
        totalMarks['writtenDiscourse'] += item.length * 2
        let totalAmount = item.length * 7
        totalMarks.writing = totalMarks.writing + (totalAmount / 2)
        totalMarks.reading = totalMarks.reading + (totalAmount / 2)
      } else if (key == 7) {
        totalMarks['grammar'] += item.length * 4
        totalMarks['spelling'] += item.length * 3
        totalMarks['vocabulary'] += item.length * 4
        totalMarks['writtenDiscourse'] += item.length * 4
        let totalAmount = item.length * 15
        totalMarks.writing = totalMarks.writing + totalAmount
      } else if (key == 8) {
        let totalOpt = 0
        _.each(item, (item1) => {
          totalOpt = totalOpt + (item1.textArray.length - 1)
        })
        let totalAmount = totalOpt
        totalMarks.writing = totalMarks.writing + (totalAmount / 2)
        totalMarks.reading = totalMarks.reading + (totalAmount / 2)
      } else if (key == 9) {
        let totalAmount = item.length
        totalMarks.reading = totalMarks.reading + totalAmount
      } else if (key == 10) {
        let totalOpt = 0
        _.each(item, (item1) => {
          let findCorrect = _.filter(item1.mcqOptions, (itemIn) => {
            return itemIn.correct
          })
          totalOpt = totalOpt + findCorrect.length
        })
        totalMarks.reading = totalMarks.reading + totalOpt
      }
      else if (key == 11) {
        let totalAmount = 0
        _.each(item, (item1) => {
          totalAmount = totalAmount + item1.reorderParagraph.length
        })
        totalMarks.reading = totalMarks.reading + totalAmount
      } else if (key == 12) {
        let totalOpt = 0
        _.each(item, (item1) => {
          totalOpt = totalOpt + (item1.textArray.length - 1)
        })
        let totalAmount = totalOpt
        totalMarks.reading = totalMarks.reading + totalAmount
      } else if (key == 13) {
        totalMarks['grammar'] += item.length * 3
        totalMarks['spelling'] += item.length * 2
        totalMarks['vocabulary'] += item.length * 2
        totalMarks['writtenDiscourse'] += item.length * 3

        let totalAmount = item.length * 10
        totalMarks.writing = totalMarks.writing + (totalAmount / 2)
        totalMarks.listening = totalMarks.listening + (totalAmount / 2)
      } else if (key == 14) {
        let totalOpt = 0
        _.each(item, (item1) => {
          let findCorrect = _.filter(item1.mcqOptions, (itemIn) => {
            return itemIn.correct
          })
          totalOpt = totalOpt + findCorrect.length
        })
        totalMarks.listening = totalMarks.listening + totalOpt
      } else if (key == 15) {
        let totalOpt = 0
        _.each(item, (item1) => {
          totalOpt = totalOpt + (item1.textArray.length - 1)
        })
        let totalAmount = totalOpt
        totalMarks.listening = totalMarks.listening + totalAmount
      } else if (key == 16) {
        let totalOpt = 0
        _.each(item, (item1) => {
          let findCorrect = _.filter(item1.mcqOptions, (itemIn) => {
            return itemIn.correct
          })
          totalOpt = totalOpt + findCorrect.length
        })
        let totalAmount = totalOpt
        totalMarks.listening = totalMarks.listening + (totalAmount / 2)
        totalMarks.reading = totalMarks.reading + (totalAmount / 2)
      } else if (key == 17) {
        let totalAmount = item.length
        totalMarks.listening = totalMarks.listening + totalAmount
      } else if (key == 18) {
        let totalOpt = 0
        _.each(item, (item1) => {
          let findCorrect = _.filter(item1.mcqOptions, (itemIn) => {
            return itemIn.correct
          })
          totalOpt = totalOpt + findCorrect.length
        })
        let totalAmount = totalOpt
        totalMarks.listening = totalMarks.listening + totalAmount
      } else if (key == 19) {
        let totalOpt = 0
        _.each(item, (item1) => {
          totalOpt = totalOpt + item1.mcqOptions.length
        })
        let totalAmount = totalOpt
        totalMarks.listening = totalMarks.listening + totalAmount
      }
    })
  }

  render() {
    const { dispatch } = this.props
    const { obtainedMarksResult, obtainMarks, score } = this.state
    const columns = [

      {
        title: 'Question',
        width: 350,
        render: (u, record) => {
          return this.renderQuestion(record)

        }
      },
      {
        title: 'Type',
        dataIndex: 'taskName',
        key: 'taskName',
        render: (text, record) => {
          return (
            <div>
              {record.taskName ? record.taskName : ''}<br/>
              <b style={{ paddingTop: 10 }}>Total:{record.marks ? record.marks : ''}</b>
            </div>

          )
        }
      },
      {
        title: 'Task No',
        key: 'taskId',
        dataIndex: 'taskId'
      },
      {
        title: 'Section',
        key: 'section',
        dataIndex: 'section',

        render: (text, record) => {
          return (
            <div>
              {record ? record.section : ''}

            </div>

          )
        }

      },
      {
        title: 'Answers',
        key: 'answers',
        width: 350,
        dataIndex: 'answers',
        render: (u, record) => {
          return (
            <div>
              {record.taskId == 19 ?
                <ul>
                  {u.options.length && u.options.map((item, index1) => {
                    return <li key={index1}>{item.value}</li>
                  })}
                </ul> : this.renderAnswer(u)
              }
            </div>
          )
        }
      },
      {
        title: 'Obtained',
        key: 'enableSkillMarks',
        dataIndex: 'enableSkillMarks',
        width: 150,
        render: (text, record) => {
          console.log(text)
          return (
            <ExtraMarksClass
              countMarksCallback={(data, id) => this.countMarksCallback(data, id)}>{record}</ExtraMarksClass>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper
        title={`Test Status`}>
        <Card bordered={true} style={{ marginBottom: 10 }}>

          <div>
            <Row>
              <Col span={8}>
                <b>Test Name: {this.state && this.state.testName}</b>

              </Col>
              <Col span={8}>
                <b>Test Date: {moment(this.state.testDate).format('YYYY-MM-DD')} </b>

              </Col>

              <Col span={8}>
                <b>Test Number: {this.state && this.state.testNo}</b>

              </Col>


            </Row>
          </div>

        </Card>

        <Card bordered={true} style={{ marginBottom: 10 }}>
          <div>
            <Row>
              <Col>
                <Table
                  bordered
                  columns={columns}
                  rowKey={record => record._id}
                  size={this.state.size}
                  dataSource={this.state && this.state.studentTestData}
                  pagination={false}
                  onChange={() => {
                  }}
                  loading={this.props.loading}
                />
              </Col>
            </Row>
            <Row style={{ padding: 10 }}>
              {/*  <Col span={6}>

                {Object.keys(obtainMarks).map(function(key) {
                  return <div>{nameObj[key]} : {obtainMarks[key]}</div>
                })}
              </Col>
              <Col span={6}>
                {Object.keys(score).map(function(key) {
                  return <div>{nameObj[key]} : {score[key]}</div>
                })}
              </Col>
              <Col span={6}>
                {Object.keys(totalMarks).map(function(key) {
                  return <div>{nameObj[key]} : {totalMarks[key]}</div>
                })}
              </Col>*/}
              <Col span={24}>
                <Row>
                  <Col span={24}> Feedback </Col>
                  <Col span={24}>
                  <TextArea value={this.state.feedback} onChange={(e) => {
                    this.setState({ feedback: e.target.value })
                  }}/>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="primary" htmlType="submit" loading={this.props.loading} style={{ marginTop: 20 }}
                        onClick={() => {
                          this.updateMarks()
                        }}>
                  SAVE
                </Button>
              </Col>
            </Row>
          </div>


        </Card>
      </PageHeaderWrapper>)

  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  loading: global.buttonLoading,
  search: router.location.search


})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(checkNowPte)
