import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Request from '../../request'
import { Card, Row, Col } from 'antd'


class Dashboard extends Component {

  state = {
    pie: [],
    avg: []
  }

  constructor(props) {
    super(props)
  }


  async componentDidMount() {

    let { pie, avg } = await Request.chartApi()

    this.setState({ pie, avg })


  }

  render() {

    let { pie, avg } = this.state
    return (
      <div>
        <Card>
          <Row>
            <Col span={12}>
              <div style={{ width: '450px' }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'column'
                    },
                    title: {
                      text: 'Test Given By Number of Students'
                    },

                    xAxis: {
                      type: 'category'
                    },
                    yAxis: {
                      title: {
                        text: 'Number of Students'
                      }

                    },
                    legend: {
                      enabled: false
                    },
                    plotOptions: {
                      series: {
                        borderWidth: 0,
                        dataLabels: {
                          enabled: true,
                          format: '{point.y} students'
                        }
                      }
                    },

                    tooltip: {
                      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} students</b> <br/>'
                    },

                    series: [
                      {
                        colorByPoint: true,
                        data: pie
                      }
                    ]

                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{ width: '500px' }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      type: 'pie'
                    },
                    title: {
                      text: 'Average Marks Scored Per Section'
                    },
                    tooltip: {
                      pointFormat: '{series.name}: <b>{point.percentage:.1}</b>'
                    },
                    plotOptions: {
                      pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                          enabled: true,
                          format: '<b>  {point.name} (Avg = {point.y} Marks)</b> ',
                          connectorColor: 'silver'
                        }
                      }
                    },
                    series: [{
                      /* data: [{name:'sdfds',y:1}]*/
                      data: avg
                    }]
                  }}
                />
              </div>
            </Col>
          </Row>

        </Card>

        <br/>
        <br/>





      </div>
    )
  }

}

export default Dashboard

