import React, { Component } from 'react'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { showLoader, hideLoader } from '../../../modules/actions'

import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Select,
  Row, Col,
  notification,
  Drawer,
  Switch,
  Form,
  Input, Button, Icon,
  Popconfirm
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
// import styles from './styles.less'
import { connect } from 'react-redux'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import styles from './styles.less'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
// import {Form} from 'antd/lib/form'

const Option = Select.Option

@Form.create()
class ChangePassword extends Component {
  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        this.props.handleSubmit(valData)
        form.resetFields()

      }
    })

  }

  render() {
    const { submitting, dispatch } = this.props

    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    let inputTypes = {
      fields: [
        {
          label: 'Current Password',
          key: 'currentPassword',
          type: 'password',
          required: true
        },
        {
          label: 'New Password',
          key: 'password',
          type: 'password',
          required: true
        }
      ]
    }

    return (
      <div>
        <Form onSubmit={this.handleSubmit} style={{ marginTop: 8 }}>
          <GetAllFormFields
            inputSchema={inputTypes}
            getFieldDecorator={getFieldDecorator}

          />
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginBottom: 10, marginLeft: '33%' }}
            loading={this.props.loading}>
            SAVE
          </Button>
        </Form>
      </div>
    )
  }
}


class AllStudents extends Component {

    constructor(props) {
        super(props)
        this.state = {
            studentType: '',
            studentArray: [],
            testName: '',
            testDate: '',
            testNumber: '',
            id: '',
            showActivity: false,
            activityArr: []
        }
        this.table = React.createRef()

    }

    async componentDidMount() {

        // let searchParams = new URLSearchParams(this.props.search)
        //
        // let testId = searchParams.get('id')

        let array = ['Inhouse Student', 'Direct Student']
        this.setState({array: array})

    }


    apiRequest = (params, columns) => {
    return new Promise(async (resolve) => {

      if (params.userType == 'Inhouse Student') {
        params.userType = 'Inhouse Student'
      }
      else if (params.userType == 'Direct Student') {
        params.userType = 'Direct Student'
      }
      else if (params.userType == 'Direct Student' && params.userType == 'Inhouse Student') {
        params.userType = 'Direct Student'
      }
      else {
        params.userType = ['Inhouse Student', 'Direct Student']

      }


      let data = await Request.getAllUser({
        ...params, regExFilters: ['name', 'email', 'mobile', 'dob', 'address']
      })

      this.setState({ totalCount: data.total })

      resolve(data)

    })
  }
  paymentDetails = async data1 => {
    this.setState({ loading: true })
    let { data } = await Request.getPaymentDetails(data1)
    this.setState({
      paymentData: data
    })
    this.setState({ loading: false })


  }
  bookedDetails = async data1 => {
    this.setState({ loading: true })
    let { data } = await Request.getBookedTest(data1)
    this.setState({ bookedTest: data })
    this.setState({ loading: false })
  }
  examHistory = async data1 => {
    this.setState({ loading: true })
    let { data } = await Request.examHistory(data1)
    this.setState({ examHistory: data })
    this.setState({ loading: false })


  }


  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  showDrawer1 = () => {
    this.setState({
      visible1: true
    })
  }

  showDrawer2 = () => {
    this.setState({
      visible2: true
    })
  }

  showDrawer3 = () => {
    this.setState({
      visible3: true
    })
  }

    loadUserActivity = async (_id) => {
        this.setState({loading: true})
        let data = await Request.loginActivity({userId: _id})

        this.setState({activityArr: data})
        this.setState({loading: false})
    }


  async lockUnlockStudent(data) {
    let resp = await Request.lockUnlockStudent(data)
    if (resp.success) {
      this.table.current.reload()
      notification.success({
        message: resp.message,
        duration: 20
      })
    } else {
      notification.error({
        message: resp.message,
        duration: 20
      })
    }

  }

  render() {
    const { dispatch } = this.props
    const { studentArray, paymentData, bookedTest, examHistory, singleData, changePasswordRecord, array } = this.state

    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email'
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile'
      },
      {
        label: 'Valid Up to',
        key: 'deactivationDate',
        required: true,
        type: 'date',
        searchDateName: 'deactivationDate',
        render: (val, record) => {
          return (
            <div>
              {record.deactivationDate
                ? <div
                  className={styles.pending}>{moment(record.deactivationDate).format('DD-MMM-YYYY')}</div>
                : ''}
            </div>
          )
        }
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address'
      },
      {
        title: 'Student Type',
        dataIndex: 'userType',
        key: 'userType',
        filters: array && array.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.userType ? record.userType : ''}</div>

          )
        }
      },
      {
        label: 'DOB',
        key: 'dob',
        required: true,
        type: 'date',
        searchDateName: 'dob',
        render: (val, record) => {
          return (
            <div>
              {record.dob
                ? moment(record.dob).format('DD-MMM-YYYY')
                : ''}
            </div>
          )
        }
      },

      {
        key: 'actions',
        title: 'Actions', fixed: 'right',
        width: 190,
        render: (text, record) => {
          return <React.Fragment>
            <Tooltip title="View Payments">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                onClick={() => {
                  this.setState({
                    singleData: record
                  }, () => {
                    this.showDrawer()
                    this.paymentDetails({ _id: record._id })

                  })
                }}
                icon="dollar"
              />
            </Tooltip>
            <Tooltip title="View Booked Test">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                onClick={() => {
                  this.setState({
                    singleData: record
                  }, () => {
                    this.showDrawer1()
                    this.bookedDetails({ _id: record._id })

                  })
                }}
                icon="table"
              />
            </Tooltip>
            <Tooltip title="View Test History">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                onClick={() => {
                  this.setState({
                    singleData: record
                  }, () => {
                    this.showDrawer2()
                    this.examHistory({ _id: record._id })

                  })
                }}
                icon="tablet"
              />
            </Tooltip>
            <Tooltip title="Change Password">
              <Button
                shape="circle"
                onClick={() => {
                  this.setState({
                    changePasswordRecord: record
                  }, () => {
                    this.showDrawer3()

                  })

                }}
                icon="eye"
              />
            </Tooltip>

            <Tooltip title="Login/Logout Activity">
              <Button
                  shape="circle"
                  onClick={() => {
                      this.setState({
                          showActivity: true
                      }, () => {
                          this.loadUserActivity(record._id)
                      })


                  }}
                  icon="eye"
              />
            </Tooltip>

            <Tooltip title={`${record.status ? 'Active User' : 'Inactive User'}`}>
              <Popconfirm
                title={`Are you sure, you want to ${record.status ? 'Lock' : 'Un-Lock'} this student?`}
                onConfirm={() => {
                  this.lockUnlockStudent(record)
                }}
                onCancel={() => {

                }}
                okText="Yes"
                cancelText="No">
                <Button
                  className={styles.btn} shape="circle"
                  icon={record.status ? 'unlock' : 'lock'}
                />
              </Popconfirm>

            </Tooltip>

          </React.Fragment>

        }
      }

    ]
    const columns1 = [
      {
        title: 'Payment Date',
        dataIndex: 'time',
        key: 'time',
        render: (val) => {
          return <div>{val && moment(val).format('YYYY-MM-DD')}</div>
        }
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount'
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status'

      },
      {
        title: 'Payment Id',
        dataIndex: 'paymentId',
        key: 'paymentId'

      },
      {
        title: 'Status',
        dataIndex: 'msg',
        key: 'msg'

      },
      {
        title: 'Mode',
        dataIndex: 'mode',
        key: 'mode'

      },
      {
        title: 'Test Details',
        dataIndex: 'testIds',
        key: 'testIds',
        render: (val, record) => {
          return <div>{_.map(record.testIds, (item) => {
            return <div>{item.testName} ,{item.testNo}</div>
          })}</div>
        }

      }


    ]
      const colact = [
          {
              title: 'Student',
              dataIndex: 'name',
              key: 'name',

          },
          {
              title: 'Login',
              dataIndex: 'loginTime',
              key: 'loginTime',

          },
          {
              title: 'Logout',
              dataIndex: 'logoutTime',
              key: 'logoutTime',

          },
          {
              title: 'Details',
              dataIndex: 'detail',
              key: 'detail',

          },


    ]
    const columns2 = [
      {
        title: 'TestName',
        dataIndex: 'testName',
        key: 'testName'
      },
      {
        title: 'TestDuration',
        dataIndex: 'testDuration',
        key: 'testDuration'
      },
      {
        title: 'TestDate',
        dataIndex: 'testDate',
        key: 'testDate',
        render: (val) => {
          return <div>{val && moment(val).format('YYYY-MM-DD')}</div>
        }
      }


    ]


    return (
      <PageHeaderWrapper
        title={`All Students (${this.state && this.state.totalCount})`}

      >

        <Card bordered={true}>
          <div>
            <Row>
              <Col>

                <TableComp ref={this.table}
                           columns={columns}
                           apiRequest={(params) => this.apiRequest(params, columns)}/>

              </Col>

            </Row>
          </div>

        </Card>
        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible: false,
              paymentData: null
            })
          }}
          visible={this.state.visible}
          width={1000}>
          <div className={styles.drawerDiv}>
            <Card bordered={true} className={styles.drawerCard}>
              <Row>
                <Col span={6}>
                  <b>Name : </b>{this.state.singleData ? this.state.singleData.name : null}
                </Col>
                <Col span={6}>
                  <b>Mobile No : </b>{this.state.singleData ? this.state.singleData.mobile : null}
                </Col>
              </Row>
            </Card>

            <Row>

              <Col span={24}>
                <TableComp ref={this.table}
                           columns={columns1}
                           dataSource={this.state.paymentData}
                           loading={this.state && !this.state.paymentData ? true : false}
                />
              </Col>

            </Row>

          </div>


        </Drawer>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible1: false,
              bookedTest: null
            })
          }}
          visible={this.state.visible1}
          width={600}>

          <div className={styles.drawerDiv}>
            <Card bordered={true} className={styles.drawerCard}>
              <Row>
                <Col span={12}>
                  <b>Name : </b>{this.state.singleData ? this.state.singleData.name : null}
                </Col>
                <Col span={12}>
                  <b>Mobile No : </b>{this.state.singleData ? this.state.singleData.mobile : null}
                </Col>
              </Row>
            </Card>
            <Row>
              <Col>
                <TableComp ref={this.table}
                           columns={columns2}
                           dataSource={this.state.bookedTest}
                           loading={this.state && !this.state.bookedTest ? true : false}
                />
              </Col>

            </Row>
          </div>


        </Drawer>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible2: false,
              examHistory: null
            })
          }}
          visible={this.state.visible2}
          width={600}>
          <div className={styles.drawerDiv}>
            <Card bordered={true} className={styles.drawerCard}>
              <Row>
                <Col span={12}>
                  <b>Name : </b>{this.state.singleData ? this.state.singleData.name : null}
                </Col>
                <Col span={12}>
                  <b>Mobile No : </b>{this.state.singleData ? this.state.singleData.mobile : null}
                </Col>
              </Row>
            </Card>
            <Row>
              <Col>
                <TableComp ref={this.table}
                           columns={columns2}
                           dataSource={this.state.examHistory}
                           loading={this.state && !this.state.examHistory ? true : false}
                />
              </Col>

            </Row>
          </div>


        </Drawer>
        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible3: false
            })
          }}
          visible={this.state.visible3}
          width={600}>
          <div className={styles.drawerDiv}>
            <Card bordered={true} className={styles.drawerCard}>
              <Row>
                <Col span={12}>
                  <b>Name : </b>{changePasswordRecord ? changePasswordRecord.name : null}
                </Col>
                <Col span={12}>
                  <b>Mobile No : </b>{changePasswordRecord ? changePasswordRecord.mobile : null}
                </Col>
              </Row>
            </Card>
            <Card bordered={true} className={styles.drawerCard} style={{ marginTop: 10 }}>
              <div style={{ textAlign: 'center', padding: 20, fontSize: 18 }}>
                Change Password for
                {changePasswordRecord && <b> {changePasswordRecord.name}</b>}
              </div>
              <ChangePassword
                loading={this.props.loading}
                handleSubmit={async values => {

                  dispatch(showLoader())

                  let x = await Request.changeUserPassword({
                    _id: changePasswordRecord._id,
                    newPassword: values.password,
                    currentPassword: values.currentPassword
                  })

                  if (x.error) {
                    notification.success({
                      message: x.message
                    })
                  } else {
                    notification.success({
                      message: x.message
                    })
                  }

                  this.setState({})
                  dispatch(hideLoader())
                }}
              />
            </Card>

          </div>


        </Drawer>
        <Drawer
            title=""
            placement="right"
            closable={true}
            onClose={() => {
                this.setState({
                    showActivity: false,
                })
            }}
            visible={this.state.showActivity}
            width={600}>
          <TableComp
              columns={colact}
              dataSource={this.state.activityArr}
          />
        </Drawer>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  search: router.location.search,
  loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllStudents)
